import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect, useRef, useState, useMemo, SetStateAction, Dispatch } from 'react';
import { PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faHeart, faSmile } from '@fortawesome/free-solid-svg-icons';
import { useTextInputSettable } from '../../common/custom-hook';
import { useMutation } from '@apollo/client';
import { ADD_ANNOTATION_TO_SEGMENT, AMEND_ANNOTATION_TO_SEGMENT } from '../../queries';
import moment from 'moment';
import { progressBarStore } from '../../context/ProgressBarContext';
import { useQuery } from '@apollo/client';
import { GET_IS_EDIT_MODE } from '../../reactivities/operations/queries/editMode/getEditMode.query';

type ThreadPopoverProps = {
  annotations: SegmentAnnotation[];
  segmentFingerprint: string;
  toggle: () => void;
};

type ThreadEditorProps = {
  value?: string;
  readonly?: boolean;
  isEditableOnClick?: boolean;
  clearWhenSubmitted?: boolean;
  handleSubmit: (text: string) => void;
  closeTextarea?: Dispatch<SetStateAction<boolean>>;
  className?: string;
};

type ReactionsProps = {};

type stateNameOfReaction = 'isReactedThumbUp' | 'isReactedThumbDown' | 'isReactedHeart' | 'isReactedFaceSmile';

function Reactions(props: ReactionsProps) {
  const [reactions, setReactions] = useState({
    isReactedThumbUp: false,
    isReactedThumbDown: false,
    isReactedHeart: false,
    isReactedFaceSmile: false,
  });

  function handleClickedReaction(stateNameOfReaction: string) {
    const currentState = reactions[stateNameOfReaction as stateNameOfReaction];

    setReactions({
      ...reactions,
      [stateNameOfReaction]: !currentState,
    });
  }

  return (
    <div className="reactions">
      <div
        className={`reactions__item reactions__thumb-up ${(reactions.isReactedThumbUp && 'reacted') || ''}`}
        onClick={() => handleClickedReaction('isReactedThumbUp')}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faThumbsUp} width={12} height={12} />
        </span>
      </div>
      <div
        className={`reactions__item reactions__thumb-down ${(reactions.isReactedThumbDown && 'reacted') || ''}`}
        onClick={() => handleClickedReaction('isReactedThumbDown')}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faThumbsDown} width={12} height={12} />
        </span>
      </div>
      <div
        className={`reactions__item reactions__heart ${(reactions.isReactedHeart && 'reacted') || ''}`}
        onClick={() => handleClickedReaction('isReactedHeart')}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faHeart} width={12} height={12} />
        </span>
      </div>
      <div
        className={`reactions__item reactions__face-smile ${(reactions.isReactedFaceSmile && 'reacted') || ''}`}
        onClick={() => handleClickedReaction('isReactedFaceSmile')}
      >
        <span className="icon">
          <FontAwesomeIcon icon={faSmile} width={12} height={12} />
        </span>
      </div>
    </div>
  );
}

function ThreadEditor(props: ThreadEditorProps) {
  const [userHasChanged, setUserHasChanged] = useState(false);
  const textInput = useTextInputSettable(props.value ?? '', undefined, () => setUserHasChanged(true));
  const [canEditable, setCanEditable] = useState(false);

  function handleEdit() {
    if (props.isEditableOnClick) {
      setCanEditable(true);
      setUserHasChanged(false);
    }
  }

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  return (
    <span style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
      <textarea
        className={props.className}
        placeholder="Leave your comment..."
        autoFocus={true}
        title={props.isEditableOnClick ? 'Click to edit' : ''}
        ref={textAreaRef}
        onBlur={() => {
          setCanEditable(false);
          if (userHasChanged) {
            props.handleSubmit(textInput.value);
            if (props.clearWhenSubmitted) textInput.setvalue('');
            if (props.closeTextarea) props.closeTextarea(false);
          } else {
            if (props.closeTextarea) props.closeTextarea(false);
          }
        }}
        onClick={handleEdit}
        readOnly={!canEditable && props.readonly}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            textAreaRef.current?.blur();
          }
        }}
        value={textInput.value}
        onChange={textInput.onChange}
      ></textarea>
    </span>
  );
}

export default function ThreadPopover({ annotations, segmentFingerprint, toggle }: ThreadPopoverProps) {
  const { isAuthenticated, user } = useAuth0();
  const [isReply, setIsReply] = useState(false);
  const { pushProgress, completeProgress } = useContext(progressBarStore);
  const [saveNewAnnotation, { loading: loadingSaveNewAnnotation }] = useMutation<{
    createAnnotation: AssemblyVersion;
    segmentFingerprint: string;
    text: string;
  }>(ADD_ANNOTATION_TO_SEGMENT, {
    onCompleted: () => {
      completeProgress('addAnnotation');
    },
  });
  const [amendAnnotation, { loading: loadingAmendAnnotation }] = useMutation<{
    createAnnotation: AssemblyVersion;
    annotationId: number;
    text: string;
  }>(AMEND_ANNOTATION_TO_SEGMENT, {
    onCompleted: () => {
      completeProgress('amendAnnotation');
    },
  });

  // useEffect(() => {
  //   const handleClickOutside: EventListener = (event: Event): void => {
  //     toggle();
  //   }
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   }
  // })

  function renderThreadCard() {
    if (!annotations?.length) return null;

    return annotations.map(item => {
      let title = item.user?.full_name || '';
      if (item.user?.email) title += ` <${item.user?.email}>`;
      return (
        <div key={item.id} className="thread-card">
          <div className="thread-card__topbar">
            <div className="thread-card__topbar-avatar">
              <img src={item.user?.avatar} alt="avatar" className="user-picture" />
            </div>
            <div className="thread-card__topbar-author">
              <span title={title}>{item.user?.full_name || item.user?.email}</span>
            </div>
            <div className="thread-card__topbar-time">
              <span
                title={
                  item.last_modification_date
                    ? `Edited ${moment.unix(Number(item.last_modification_date) / 1000).fromNow()}`
                    : ''
                }
              >
                {moment.unix(Number(item.creation_date) / 1000).fromNow()}
                {item.last_modification_date && '*'}
              </span>
            </div>
          </div>
          <div className="thread-card__body">
            <ThreadEditor
              readonly={true}
              value={item.text}
              isEditableOnClick={item.is_editable}
              handleSubmit={text => {
                pushProgress('amendAnnotation');
                amendAnnotation({ variables: { annotationId: item.id, text } });
              }}
            />
          </div>
        </div>
      );
    });
  }

  const isEditMode = useQuery(GET_IS_EDIT_MODE);
  const renderAddComment = useMemo(() => {
    if (!isAuthenticated) return <div className="login-required-note">Please login to comment</div>;

    if (!isReply && annotations?.length) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }} className="reply">
          {/* <Reactions /> */}
          <div style={{ flex: 1, backgroundColor: 'transparent' }}></div>
          <div className="btn-reply" onClick={() => setIsReply(true)}>
            Reply
          </div>
        </div>
      );
    }

    return (
      <div className="thread-card thread-comment">
        <div className="thread-card__topbar">
          <div className="thread-card__topbar-avatar">
            <img src={user.picture} alt="avatar" />
          </div>
          <div className="thread-card__topbar-author">
            <span>Add a comment</span>
          </div>
        </div>
        <div className="thread-card__body">
          <ThreadEditor
            readonly={loadingSaveNewAnnotation}
            isEditableOnClick={!loadingSaveNewAnnotation}
            handleSubmit={text => {
              if (text) {
                pushProgress('addAnnotation');
                saveNewAnnotation({ variables: { text, segmentFingerprint } });
              }
            }}
            closeTextarea={setIsReply}
            clearWhenSubmitted={true}
          />
          {/* <Reactions /> */}
        </div>
      </div>
    );
  }, [isReply, isAuthenticated, annotations]);

  return (
    <PopoverBody>
      <div className="thread-popover">
        <div className="ctn-thread-cards">{renderThreadCard()}</div>
        {renderAddComment}
      </div>
    </PopoverBody>
  );
}

import React, { useEffect, useRef, useState, useCallback, useMemo, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlay,
  faPause,
  faStop,
  faStepForward,
  faStepBackward,
  faTrashAlt,
  faPlusCircle,
  faGripVertical,
  faPollH,
} from '@fortawesome/free-solid-svg-icons';
import {
  Container,
  Row,
  Col,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  Popover,
} from 'reactstrap';
import SegmentCard from './SegmentCard';
import moment, { duration, Duration } from 'moment';
import {
  useDrop,
  useDrag,
  DndProvider,
  DropTargetMonitor,
  DragSourceMonitor,
  DragLayerMonitor,
  useDragLayer,
} from 'react-dnd';
import { HTML5Backend, getEmptyImage } from 'react-dnd-html5-backend';
import { useTextInputSettable } from '../../common/custom-hook';
import TimelineEditor from '../VideoEditMode/TimelineEditor';

const NUM_OF_ITEMS_PER_BATCH = 3;

enum ItemTypes {
  CHAPTER_ITEM = 'CHAPTER_ITEM',
  CHAPTER = 'CHAPTER',
}

type AssemblyProps = {
  readOnly?: boolean;
  segments: Segment[];
  currentlyPlayingVideo: number | null;
  visitors: Visitor[];
  hasBucketFocused: boolean;
  areColumnsOpen: boolean;
  isModeVideoEdit: boolean;
  listLanguageRegionCode?: string[];
  handleRenameChapter: (index: number, segment: Segment) => void;
  handleCreateChapter: (chapterName: string, index: number | null) => void;
  handleRemoveChapter: (indexes: number[]) => void;
  handleReorderChapter: (segments: Segment[]) => void;
  handleRemoveSegment: (index: number) => void;
  handleReorderSegment: (previousIndex: number, nextIndex: number) => void;
  handleReorderSegments: (previousIndexes: number[], nextIndexes: number[]) => void;
  setCurrentlyPlayingVideo: (currentlyPlayingVideo: number | null) => void;
  setCurrentTimePlaying: (timestamp: number | null) => void;
  handleSelectedImportedMedia: (media: ImportedMedia | null) => void;
  handleInsertTransition: (index: number, segment: Segment) => void;
};

enum StateOfBatch {
  INIT = 'INIT',
  SUCCESSED = 'SUCCESSED',
  TIME_OUT = 'TIME_OUT',
}

enum PlaybackSpeed {
  '2x' = '2',
  '1.75x' = '1.75',
  '1.5x' = '1.5',
  '1.25x' = '1.25',
  '1x' = '1',
  '0.75x' = '0.75',
  '0.5x' = '0.5',
}

declare type PlaybackSpeedType = keyof typeof PlaybackSpeed;

export default function Assembly({
  readOnly,
  segments,
  currentlyPlayingVideo,
  visitors,
  hasBucketFocused,
  areColumnsOpen,
  isModeVideoEdit,
  listLanguageRegionCode,
  handleRemoveSegment,
  handleReorderSegment,
  setCurrentlyPlayingVideo,
  setCurrentTimePlaying,
  handleSelectedImportedMedia,
  handleCreateChapter,
  handleRenameChapter,
  handleRemoveChapter,
  handleReorderChapter,
  handleReorderSegments,
  handleInsertTransition,
}: AssemblyProps) {
  const cardHolder = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const canvasPlayerRef = useRef<HTMLDivElement>(null);
  const controlsRef = useRef<HTMLDivElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [isPaused, setIsPaused] = useState(true);
  const [knownVideos, setKnownVideos] = useState<
    { idxInSegments: number; idxInKnownVideos: number; element: HTMLVideoElement }[]
  >([]);

  const [currenPlayBackSpeed, setCurrenPlayBackSpeed] = useState<PlaybackSpeed>(PlaybackSpeed['1x']);
  const [orderBatch, setOrderBatch] = useState<{ [key: number]: StateOfBatch }[]>([]);
  const [currentBatch, setCurrentBatch] = useState<{ [key: number]: StateOfBatch }[]>([]);
  const [selectedIdxSegments, setSelectedIdxSegments] = useState<{ segmentIdx: number; chapterFingerprint: string }[]>(
    [],
  );
  const isLoadedBatches = useRef(false);
  const orderBatchFingerprints = useRef<string[] | null>(null);
  const currentDuration = useRef(0);
  const isJumpToPointWhenLoaded = useRef(false);
  const justCreatedChapterIdx = useRef<number | null>(null);
  const justDroppedChapter = useRef<{ index: number; fingerprint: string } | null>(null);
  const [isOpenVisitorPopover, setIsOpenVisitorPopover] = useState(false);
  const currentTimePlayerRef = useRef<any>(null);
  const globalCurrentTimeRef = useRef<any>(null);

  // utility functions relating to videos
  const drawOnCanvas = useCallback(media => {
    /**
     * the input media can be an img element because the audio type use img is a representation
     * when we pass the input in the function requestAnimationFrame below
     * this condition guarantee the input media always is video element
     */
    if (media.nodeName === 'IMG') media = media.parentNode.querySelector('video');
    const type = media.querySelector('source')?.type;
    const isAudio = /audio\/mp3/.test(type);

    let video = media;
    let img: any = null;
    if (isAudio) img = media.parentNode.querySelector('img');

    let vw = 0;
    let vh = 0;
    const videoContainer = videoContainerRef.current;
    const canvas = canvasRef.current;
    const controls = controlsRef.current;
    const ctx = canvas?.getContext('2d');

    if (!videoContainer || !controls || !canvas || !ctx || !video) return;
    if (isAudio && !img) return;

    const desiredWidth = videoContainer.offsetWidth;
    const desiredHeight = videoContainer.offsetHeight;

    if (isAudio) {
      vw = img.naturalWidth || img.width;
      vh = img.naturalHeight || img.height;
    } else {
      vw = video.videoWidth || video.width;
      vh = video.videoHeight || video.height;
    }

    const yScaleFactor = desiredWidth / vw;
    const xScaleFactor = (desiredHeight - controls.offsetHeight - 30) / vh;
    let scaleFactor = Math.min(yScaleFactor, xScaleFactor);

    if (scaleFactor > 1) scaleFactor = 1;

    if (video.ended || video.paused) {
      if (video.ended) ctx.clearRect(0, 0, canvas.width, canvas.height);
      return false;
    }

    if (vw * scaleFactor > 0) {
      canvas.width = vw * scaleFactor;
      canvas.height = vh * scaleFactor;
      ctx.drawImage(isAudio ? img : video, 0, 0, vw * scaleFactor, vh * scaleFactor);
    }

    requestAnimationFrame(() => drawOnCanvas(isAudio ? img : video));
  }, []);

  useEffect(() => {
    console.log('[Playback] Segments have changed. Scanning page for video elements and updating refrences');
    const videos: { idxInSegments: number; idxInKnownVideos: number; element: HTMLVideoElement }[] = [];
    let cardNodes: Element[] = [];
    let count = 0;

    if (cardHolder.current?.children.length) {
      cardNodes = Array.from(cardHolder.current.children).filter(c => {
        // segment-holder is identity class for segments(chapter and chapter item)
        return c.classList.contains('segment-holder');
      });
    }

    if (cardNodes.length >= 1) {
      for (let idx = 0; idx < cardNodes.length; idx++) {
        // the chapter type mustn't have video element
        const htmlVideoElement = cardNodes[idx].querySelector('video');
        if (htmlVideoElement) {
          videos.push({
            idxInSegments: idx,
            idxInKnownVideos: count,
            element: htmlVideoElement,
          });
          count++;
        }
      }
    }

    setKnownVideos(videos);

    return () => {
      //clear all listeners on video elements before changing them
      console.log(
        '[Playback] Clearing all playback chaining rules *before* references to videos change, preventing ensure no chaining rules remain on inaccessible videos',
      );
      setKnownVideos(oldKnownVideos => {
        oldKnownVideos.forEach(videoObj => {
          const video = videoObj.element;

          video.onpause = null;
          video.onplay = null;
          video.ontimeupdate = null;
        });
        return oldKnownVideos;
      });
    };
  }, [segments]);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      if (isJumpToPointWhenLoaded.current) return;
      if (!/(?:\?|\&)(?:t=)+(\d*$)/.test(url)) return;
      if (!knownVideos.length) return;

      let playingTime = /(?:\?|\&)(?:t=)+(\d*$)/.exec(url)![1];
      let index = 0;
      let countDuration = 0;
      let prevDuration = 0;

      for (const segment of segments) {
        const duration = Math.round(segment.end - segment.start);
        countDuration += duration;

        if (+playingTime! > countDuration) {
          index++;
          prevDuration = duration;
          continue;
        }

        break;
      }

      const media = knownVideos[index];
      const segment = segments[index];

      if (media && segment) {
        // setIsPaused(true);
        // media.pause();
        knownVideos[index].element.currentTime = segment.start + (+playingTime - prevDuration);
        setCurrentlyPlayingVideo(index);
        // media.play();
        // setIsPaused(false);

        isJumpToPointWhenLoaded.current = true;
      }
    };

    handleRouteChange(window.location.pathname + window.location.search);
  }, [knownVideos, segments]);

  const stopNextPlay = useCallback(() => {
    knownVideos.forEach(videoObj => {
      const video = videoObj.element;
      video.onpause = null;
      video.ontimeupdate = null;
    });
  }, [knownVideos]);

  const setupBlittingToCanvas = useCallback(() => {
    knownVideos.forEach(videoObj => {
      const video = videoObj.element;
      const index = videoObj.idxInKnownVideos;

      video.onplay = () => {
        setCurrentlyPlayingVideo(index);
        drawOnCanvas(video);
      };
    });
  }, [knownVideos, drawOnCanvas, setCurrentlyPlayingVideo]);

  const recalculateCurrentlyPlayingVideo = useCallback(() => {
    knownVideos.forEach(videoObj => {
      const video = videoObj.element;
      const index = videoObj.idxInKnownVideos;

      if (video && !video.paused && !video.ended) {
        setCurrentlyPlayingVideo(index);
      }
    });
  }, [knownVideos, setCurrentlyPlayingVideo]);

  const resetPlaybackPositionForAll = useCallback(
    exceptPlaying => {
      if (segments.filter(seg => !seg.is_chapter).length !== knownVideos.length) {
        console.log(
          '[Playback][WARNING] Skipping resetting playback position of all videos until segments and videos match',
        );
        return;
      }
      knownVideos.forEach(videoObj => {
        const video = videoObj.element;
        const index = videoObj.idxInSegments;

        if (video && (!exceptPlaying || video.paused || video.ended)) {
          video.pause();
          video.currentTime = segments[index].start;
        }
      });
    },
    [knownVideos, segments],
  );

  function updateCurrentTimePlayer(time: number | null) {
    if (time === null) return;
    if (currentTimePlayerRef.current) {
      currentTimePlayerRef.current.innerText = formatTime(time);
    }
  }

  function updateTimeToTimeLine(time: number | null) {
    if (globalCurrentTimeRef.current) {
      globalCurrentTimeRef.current.updateGlobalTime(time);
      updateCurrentTimePlayer(time);
    }
  }

  const setupNextPlayAfterStop = useCallback(() => {
    if (segments.filter(seg => !seg.is_chapter).length !== knownVideos.length) {
      console.log('[Playback][WARNING] Skipping setting up playback chaining rules until segments and videos match');
      return;
    }

    knownVideos.forEach((videoObj, idx) => {
      const video = videoObj.element;
      const index = videoObj.idxInKnownVideos;
      const segment = segments[videoObj.idxInSegments];

      if (!segment) return;
      const endTime = segment.end;

      video.ontimeupdate = e => {
        let durationBefore = 0;
        knownVideos.forEach((v, index) => {
          if (index < idx) {
            durationBefore += segments[v.idxInSegments].end - segments[v.idxInSegments].start;
          }
        });

        if (!video.paused && video.readyState >= 2) {
          const currentTime = durationBefore + video.currentTime - segment.start;
          updateTimeToTimeLine(currentTime);
        }

        if (video.currentTime > endTime) video.pause();
      };

      if (index < knownVideos.length - 1) {
        const nextVideo = knownVideos[index + 1];
        video.onpause = () => {
          nextVideo.element.play();
        };
      } else {
        video.onpause = async e => {
          updateTimeToTimeLine(null);
          setCurrentlyPlayingVideo(null);
          setIsPaused(true);
          stopNextPlay();
          resetPlaybackPositionForAll(false);
          //wait for the video elements to update (no known callback we can use for this)
          await new Promise(resolve => setTimeout(resolve, 300));
          setupNextPlayAfterStop();
        };
      }
    });
  }, [knownVideos, segments, resetPlaybackPositionForAll, stopNextPlay, setCurrentlyPlayingVideo]);

  // -- end utility functions relating to videos
  const clearSelection = () => {
    const selection = window.getSelection();
    selection && selection.removeAllRanges();
  };

  const handlePlayClicked = useCallback(
    async (forceToSpecific?: number, currentTime?: number) => {
      if (knownVideos.length) {
        let targetIndex = currentlyPlayingVideo;

        if (forceToSpecific !== undefined) {
          targetIndex = forceToSpecific;
        }
        console.log('[Playback] forcing playback to index ' + targetIndex);
        setIsPaused(false);
        setupNextPlayAfterStop();
        setupBlittingToCanvas();

        if (currentTime !== undefined && targetIndex !== null) {
          knownVideos[targetIndex].element.currentTime = currentTime;
        }

        if (targetIndex !== null) {
          knownVideos[targetIndex].element.play();
        } else {
          knownVideos[0].element.play();
        }
      }
    },
    [knownVideos, currentlyPlayingVideo, setupNextPlayAfterStop, setupBlittingToCanvas],
  );

  const handlePauseClicked = useCallback(async () => {
    if (knownVideos.length > 0 && currentlyPlayingVideo !== null) {
      setIsPaused(true);
      stopNextPlay();
      await new Promise(resolve => setTimeout(resolve, 300));
      knownVideos[currentlyPlayingVideo].element.pause();
    }
  }, [knownVideos, currentlyPlayingVideo, stopNextPlay]);

  const handleStopClicked = useCallback(async () => {
    setCurrentlyPlayingVideo(null);
    setIsPaused(true);

    if (knownVideos.length) {
      updateTimeToTimeLine(null);
      stopNextPlay();
      resetPlaybackPositionForAll(false);
      //wait for the video elements to update (no known callback we can use for this)
      await new Promise(resolve => setTimeout(resolve, 300));

      setupNextPlayAfterStop();
    }
  }, [knownVideos, resetPlaybackPositionForAll, setupNextPlayAfterStop, stopNextPlay, setCurrentlyPlayingVideo]);

  useEffect(() => {
    console.log('[Playback] Video references have changed. Setting up playback chaining rules for each video');
    const reset = async () => {
      setupNextPlayAfterStop();
      setupBlittingToCanvas();
      recalculateCurrentlyPlayingVideo();
    };
    reset();
    return () => {
      stopNextPlay();
    };
  }, [knownVideos, setupNextPlayAfterStop, setupBlittingToCanvas, stopNextPlay, recalculateCurrentlyPlayingVideo]);

  // set the canvas to black on mount
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas && canvas.getContext('2d');
    if (ctx) {
      ctx.fillStyle = 'black';
    }
  }, []);

  useEffect(() => {
    function hasFocus(selector: string) {
      return Array.from(document.querySelectorAll(selector)).some(function(el) {
        return el === document.activeElement;
      });
    }

    // @ts-ignore
    function handleKeydown(e) {
      if (e.keyCode === 32 /* `space` key */ && !hasFocus('input, select, textarea')) {
        // e.preventDefault();
        if (!hasBucketFocused) {
          if (isPaused) handlePlayClicked();
          else handlePauseClicked();
        }
      }
    }

    // @ts-ignore
    document.body.addEventListener('keydown', handleKeydown, false);

    return () => {
      // @ts-ignore
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, [hasBucketFocused, isPaused, handlePlayClicked, handlePauseClicked]);

  useEffect(() => {
    const orderBatchLength = Object.keys(orderBatch).length;
    const headBatch: { [key: number]: StateOfBatch; fingerprint: string }[] = [];
    const tailBatch: { [key: number]: StateOfBatch; fingerprint: string }[] = [];

    if (!orderBatchLength && segments.length) {
      for (let index = 0; index < segments.length; index++) {
        const segment = segments[index];

        if (currentlyPlayingVideo && index >= currentlyPlayingVideo) {
          // if(segment.is_chapter) headBatch.push({ [index]: StateOfBatch.SUCCESSED, fingerprint: segment.fingerprint });
          if (!segment.is_chapter && segment.imported_media)
            headBatch.push({ [index]: StateOfBatch.INIT, fingerprint: segment.fingerprint });
        } else {
          if (segment.is_chapter && segment.imported_media)
            tailBatch.push({ [index]: StateOfBatch.SUCCESSED, fingerprint: segment.fingerprint });
          // if(!segment.is_chapter) tailBatch.push({ [index]: StateOfBatch.INIT, fingerprint: segment.fingerprint});
        }
      }

      if (headBatch.length || tailBatch.length) {
        const orderBatch = [...headBatch, ...tailBatch];
        orderBatchFingerprints.current = orderBatch.map((item: any) => item.fingerprint);
        setOrderBatch(orderBatch);
      } else {
        orderBatchFingerprints.current = [];
      }
    }
  }, [segments]);

  useEffect(() => {
    const job = setInterval(() => {
      if (isLoadedBatches.current) clearInterval(job);
      else handleLoadBatch();
    }, 1000);

    return () => {
      clearInterval(job);
    };
  }, [orderBatch]);

  useEffect(() => {
    if (isModeVideoEdit) handleStopClicked();
  }, [isModeVideoEdit]);

  async function handleStopAndPlayMedia(index: number) {
    await handleStopClicked();
    await new Promise(resolve => setTimeout(resolve, 500));
    handlePlayClicked(index);
  }

  // Not all media has an ID when adding new segments. We want a unique identifier that
  // survives dragging and dropping (otherwise it will reload the html video)
  const renderCards = useMemo(() => {
    const currentBatchIndexes = currentBatch.map(item => +Object.keys(item)[0]);
    const currentBatchValues = currentBatch.map(item => Object.values(item)[0]);
    const cards: JSX.Element[] = [];
    const lastChapterIdx = segments.map(segment => segment.is_chapter).lastIndexOf(true);
    let isLastChapter = false;
    let currentChapterIdx = null;
    let currentChapterFingerprint = null;
    let chapterCount = 0;

    for (let index = 0; index < segments.length; index++) {
      const segment = segments[index];
      const url = `${segment?.imported_media?.url}#t=${segment.start},${segment.end}`;
      const startTimecodeInSeconds =
        moment.duration(segment?.imported_media?.startTimeCode?.substr(0, 8)).asMilliseconds() / 1000;
      const key = segment.fingerprint;
      const hasIndexInBatch = currentBatchIndexes.includes(index);
      const isJustCreated =
        orderBatchFingerprints.current !== null && !orderBatchFingerprints.current.includes(segment.fingerprint);
      const isLoadMedia = isJustCreated ? isJustCreated : currentBatchIndexes.length ? hasIndexInBatch : false;
      const isTimeOut = hasIndexInBatch && currentBatchValues[index] === StateOfBatch.TIME_OUT;

      const nextItem = segments[index + 1];
      const hasChildrenInChapter = !!(nextItem && !nextItem.is_chapter);
      const isLastItemInChapter = !!((nextItem && nextItem.is_chapter) || !nextItem);

      let numOfChapterItem = 0;

      if (segment.is_chapter) {
        currentChapterIdx = index;
        currentChapterFingerprint = segments[currentChapterIdx].fingerprint;
        chapterCount += 1;

        for (let chapterItemIdx = index + 1; chapterItemIdx < segments.length; chapterItemIdx++) {
          if (segments[chapterItemIdx].is_chapter) break;
          numOfChapterItem++;
        }
      }

      if (!isLastChapter && lastChapterIdx === index) isLastChapter = true;

      const card = (segment.is_chapter && (
        <DraggableChapter
          key={key}
          fingerprint={key}
          index={index}
          segment={segment}
          isLastChapter={isLastChapter}
          numOfChapterItem={numOfChapterItem}
          hasChildrenInChapter={hasChildrenInChapter}
          cardHolderRef={cardHolder}
          moveChapter={moveChapter}
          moveSegment={moveSegment}
          editChapterName={editChapterName}
          removeChapter={removeChapter}
          handleAddChapter={createNewChapter}
          readOnly={readOnly}
          isJustCreatedChapter={justCreatedChapterIdx.current === index}
          justDroppedChapter={justDroppedChapter}
        />
      )) || (
        <DraggableSegment
          key={key}
          cardHolderRef={cardHolder}
          isLoadMedia={isLoadMedia}
          isLastChapter={isLastChapter}
          isTimeOut={isTimeOut}
          index={index}
          chapterIdx={currentChapterIdx}
          chapterFingerprint={currentChapterFingerprint}
          segment={segment}
          url={url}
          isLastItemInChapter={isLastItemInChapter}
          startTimecodeInSeconds={startTimecodeInSeconds}
          currentlyPlayingVideo={currentlyPlayingVideo} // minus for chapterCount because we dont insert type chapter to knownvideos
          readOnly={readOnly}
          handleRemoveClicked={() => handleRemoveSegment(index)}
          handleSegmentClicked={handleSegmentClicked}
          chapterCount={chapterCount}
          handleVideoThumbnailClicked={(numOfChapterUpwards: number) =>
            handleStopAndPlayMedia(index - numOfChapterUpwards)
          } // minus for chapterCount because we dont insert type chapter to knownvideos
          handleMediaCanPlay={handleMediaCanPlay}
          moveSegment={moveSegment}
          moveChapter={moveChapter}
          handleAddChapter={createNewChapter}
          selectSegments={selectSegments}
          selectedIdxSegments={selectedIdxSegments}
          justDroppedChapter={justDroppedChapter}
        />
      );

      cards.push(card);
    }

    justCreatedChapterIdx.current = null;
    return cards;
  }, [segments, currentlyPlayingVideo, readOnly, currentBatch, selectedIdxSegments]);

  function handleSegmentClicked(segment: Segment) {
    const { imported_media, start } = segment;
    // const idx = importedMedia?.findIndex(item => item.id === imported_media.id);
    // if (imported_media) {
    //   handleSelectedImportedMedia(imported_media);
    //   setCurrentTimePlaying(start);
    // }
    handleSelectedImportedMedia(imported_media);
    setCurrentTimePlaying(start);
  }

  function getDurationAllSegments(segments: Segment[]) {
    let duration = 0;
    duration = segments.map(seg => seg.end - seg.start).reduce((prevVal, curVal) => prevVal + curVal, 0);
    return duration;
  }

  const getFormatDurationAllSegments = useMemo(() => {
    if (!segments) return null;

    currentDuration.current = getDurationAllSegments(segments);
    const result = moment.duration(String(currentDuration.current), 'seconds') as Duration;
    return result.format('hh:mm:ss', 0, { trim: false });
  }, [segments]);

  function formatTime(time: number) {
    const result = moment.duration(String(time), 'seconds') as Duration;
    return result.format('hh:mm:ss', 0, { trim: false });
  }

  function handleMediaCanPlay(index: number, isTimeOut: boolean) {
    const newCurrentBatch = [...currentBatch];
    const newOrderBatch = [...orderBatch];

    for (const item of newCurrentBatch) {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty(index) && item[index] === StateOfBatch.INIT) {
        item[index] = !isTimeOut ? StateOfBatch.SUCCESSED : StateOfBatch.TIME_OUT;
      }
    }

    for (const item of newOrderBatch) {
      // eslint-disable-next-line no-prototype-builtins
      if (item.hasOwnProperty(index) && item[index] === StateOfBatch.INIT) {
        item[index] = !isTimeOut ? StateOfBatch.SUCCESSED : StateOfBatch.TIME_OUT;
      }
    }

    setCurrentBatch(newCurrentBatch);
    setOrderBatch(newOrderBatch);
  }

  function handleLoadBatch() {
    const newCurrentBatch: { [key: number]: StateOfBatch }[] = [];
    const isInitialBatch = !Object.keys(currentBatch).length;
    const orders = Object.keys(orderBatch);
    const valuesOfCurrentBatch = Object.values(currentBatch).map(state => Object.values(state)[0]);
    const statesCurrentBatch = valuesOfCurrentBatch as StateOfBatch[];
    const areItemsLoaded = statesCurrentBatch.every((state: StateOfBatch) => state === StateOfBatch.SUCCESSED);
    const hasAnyTimeoutItem = statesCurrentBatch.some((state: StateOfBatch) => state === StateOfBatch.TIME_OUT);

    if (isInitialBatch) {
      for (const orderKey of orders) {
        const orderNum = Number(orderKey);
        if (orderNum >= 0 && orderNum < NUM_OF_ITEMS_PER_BATCH) {
          newCurrentBatch.push(orderBatch[orderNum]);
        }
      }
    }

    if (!isInitialBatch) {
      if (areItemsLoaded || hasAnyTimeoutItem) {
        const keys = Object.keys(currentBatch);
        const lastCurrentBatchIndex = Object.keys(currentBatch[keys.length - 1])[0];
        // eslint-disable-next-line no-prototype-builtins
        const lastBatchItem = orderBatch.findIndex(item => item.hasOwnProperty(+lastCurrentBatchIndex));
        const isFinisedLoadBatches = lastBatchItem === segments.length - 1;

        if (isFinisedLoadBatches) {
          isLoadedBatches.current = true;
          setCurrentBatch([]);
          return;
        }

        if (lastBatchItem) {
          const nextBatchItem = lastBatchItem + 1;
          for (const orderKey of orders) {
            const orderNum = Number(orderKey);
            if (orderNum >= nextBatchItem && orderNum < nextBatchItem + NUM_OF_ITEMS_PER_BATCH) {
              newCurrentBatch.push(orderBatch[orderNum]);
            }
          }
        }
      }
    }

    const hasNewBatch = !!Object.keys(newCurrentBatch).length;
    if (hasNewBatch) setCurrentBatch(newCurrentBatch);
  }

  async function handleBackwardClicked() {
    if (currentlyPlayingVideo !== null && knownVideos.length) {
      setIsPaused(true);
      stopNextPlay();
      await new Promise(resolve => setTimeout(resolve, 300));
      const media = knownVideos[currentlyPlayingVideo];
      if (media) {
        media.element.currentTime = segments[currentlyPlayingVideo].start; //fix currentime of video before when backward
        media.element.pause();
        const prevIndex = currentlyPlayingVideo - 1 < 0 ? knownVideos.length - 1 : currentlyPlayingVideo - 1;
        const video = knownVideos[prevIndex];
        video.element.currentTime = segments[video.idxInSegments].start;
        await new Promise(resolve => setTimeout(resolve, 100));
        handlePlayClicked(prevIndex);
      }
    }
  }

  async function handleForwardClicked() {
    if (currentlyPlayingVideo !== null && knownVideos.length) {
      setIsPaused(true);
      await new Promise(resolve => setTimeout(resolve, 300));
      const media = knownVideos[currentlyPlayingVideo];
      if (media) {
        media.element.pause();
        const nextIndex = currentlyPlayingVideo + 1 > knownVideos.length - 1 ? 0 : currentlyPlayingVideo + 1;
        const video = knownVideos[nextIndex];
        video.element.currentTime = segments[video.idxInSegments].start;
        setCurrentlyPlayingVideo(nextIndex);
        video.element.play();
        setIsPaused(false);
      }
    }
  }

  function handleChangePlaybackSpeed(playbackSpeed: string) {
    setCurrenPlayBackSpeed(PlaybackSpeed[playbackSpeed as PlaybackSpeedType]);
    knownVideos.map(videoObj => {
      const video = videoObj.element;
      video.playbackRate = Number(PlaybackSpeed[playbackSpeed as PlaybackSpeedType]);
    });
  }

  function getShortDescription(text: string) {
    if (typeof text !== 'string') return '';

    const words = text.trim().split(/\s+/);
    return words.length > 5 ? words.splice(0, 4).join(' ') + '...' : words.splice(0, 4).join(' ');
  }

  const renderSegmentsTimeLine = useMemo(() => {
    const numLimit = 4;
    const visitorsNormalization = new Map<string, Visitor[]>();

    if (visitors) {
      for (const visitor of visitors) {
        const { active_fingerprint: key } = visitor;
        if (!key) continue;

        if (!visitorsNormalization.has(key)) {
          visitorsNormalization.set(key, [visitor]);
        } else {
          visitorsNormalization.set(key, [...(visitorsNormalization.get(key) || []), visitor]);
        }
      }
    }

    return segments
      .filter(seg => !seg.is_chapter)
      .map((segment: Segment, index) => {
        const currentVisitors = visitorsNormalization.get(segment.fingerprint);

        return (
          <div
            key={'segment-timeline-' + index}
            id={'timeline-' + index}
            title={getShortDescription(segment.text)}
            style={{
              width:
                ((Math.round(segment.end - segment.start) / Math.round(currentDuration.current || 1)) * 100 || 1) + '%',
            }}
            className={`control__timeline__item ${currentlyPlayingVideo === index ? 'playing' : ''}`}
            onClick={() => handleStopAndPlayMedia(index)}
          >
            <div className="control__timeline__users" onMouseLeave={() => setIsOpenVisitorPopover(false)}>
              {currentVisitors &&
                currentVisitors.slice(0, numLimit).map((visitor, idx) => (
                  <div
                    key={'visitor-' + idx}
                    className="control__timeline__user"
                    title={visitor.full_name}
                    onClick={e => e.stopPropagation()}
                  >
                    <img src={visitor.avatar} alt="" />
                  </div>
                ))}
              {currentVisitors && currentVisitors.length > numLimit && (
                <React.Fragment>
                  <div
                    id={'timeline-visitors-popover-' + index}
                    className="control__timeline__user"
                    onClick={e => {
                      e.stopPropagation();
                      setIsOpenVisitorPopover(prev => !prev);
                    }}
                  >
                    <div className="user-placeholder" style={{ backgroundColor: '#fff' }}>
                      {currentVisitors.length - numLimit}+
                    </div>
                  </div>
                  <Popover isOpen={isOpenVisitorPopover} target={'timeline-visitors-popover-' + index}>
                    <div style={{ backgroundColor: '#14172c' }}>
                      {currentVisitors.map((visitor, idx) => (
                        <div className="control__timeline__user-popover" key={idx}>
                          <div>
                            <img src={visitor.avatar} alt="" />
                          </div>
                          <div className="user-name" title={visitor.full_name}>
                            {visitor.full_name}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Popover>
                </React.Fragment>
              )}
            </div>
            <div className={`control__timeline__thumb thumb-${index}`}>
              <img src="https://app.simonsays.ai/img/poster-audio.png" />
            </div>
          </div>
        );
      });
  }, [segments, visitors, handleStopAndPlayMedia]);

  function reorderSegment(previousIndex: number, nextIndex: number) {
    resetPlaybackPositionForAll(true);
    clearSelection();
    handleReorderSegment(previousIndex, nextIndex);
  }

  function reorderSegments(previousIndexes: number[], nextIndex: number) {
    resetPlaybackPositionForAll(true);
    clearSelection();

    const nextIndexes = previousIndexes.map((item, index) => nextIndex + index + 1);
    handleReorderSegments(previousIndexes, nextIndexes);
  }

  function moveChapter(prevIndex: number, nextIndex: number, isDroppedAtBottom: boolean = false) {
    const isDownwards = prevIndex < nextIndex;
    const newSegments = [...segments];
    let newNextIndex = nextIndex;
    let nextChapterIdx = null;

    // find next chapter
    for (let idx = prevIndex + 1; idx < segments.length; idx++) {
      const curSegment = segments[idx];
      if (!curSegment.is_chapter) continue;

      if (curSegment.is_chapter) {
        nextChapterIdx = idx;
        break;
      }
    }

    const prevChapterSegments = newSegments.splice(
      prevIndex,
      nextChapterIdx ? nextChapterIdx - prevIndex : segments.length - prevIndex,
    );

    if (!isDroppedAtBottom && isDownwards && prevChapterSegments.length + prevIndex === nextIndex) return;

    if (isDownwards) newNextIndex -= prevChapterSegments.length;
    if (!isDroppedAtBottom) newSegments.splice(newNextIndex, 0, ...prevChapterSegments);
    if (isDroppedAtBottom) newSegments.push(...prevChapterSegments);

    justDroppedChapter.current = {
      index: prevIndex,
      fingerprint: segments[prevIndex].fingerprint,
    };
    handleReorderChapter(newSegments);
  }

  function moveSegment(
    prevChapterIdx: number,
    nextChapterIdx: number,
    prevIndex: number,
    nextIndex: number,
    isDroppedOverChapter: boolean = false,
  ) {
    if (selectedIdxSegments.length <= 1) {
      reorderSegment(prevIndex, nextIndex);
      setSelectedIdxSegments([]);
    } else {
      let nextIdx = null;
      if (prevChapterIdx !== nextChapterIdx && isDroppedOverChapter) nextIdx = nextChapterIdx;
      else nextIdx = nextIndex;

      reorderSegments(
        selectedIdxSegments.map(item => item.segmentIdx),
        nextIdx,
      );
      setSelectedIdxSegments([]);
    }
  }

  function createNewChapter(chapterName: string, index: number | null = null) {
    justCreatedChapterIdx.current = index;
    handleCreateChapter(chapterName, index);
  }

  function editChapterName(index: number, newChapterName: string) {
    let segment = segments[index];
    const newSegment = {
      ...segment,
      text: newChapterName,
    };
    handleRenameChapter(index, newSegment);
  }

  function removeChapter(chapterIdx: number) {
    const indexes: number[] = [];

    for (let idx = chapterIdx + 1; idx < segments.length; idx++) {
      const segment = segments[idx];
      if (segment.is_chapter) break;
      indexes.push(idx);
    }
    handleRemoveChapter([chapterIdx, ...indexes]);
  }

  function selectSegments(segmentIdx: number | null, chapterFingerprint: string) {
    if (segmentIdx === null) {
      setSelectedIdxSegments([]);
      return;
    }

    let segment = selectedIdxSegments[0];

    if (!segment) {
      setSelectedIdxSegments([{ segmentIdx, chapterFingerprint }]);
    } else {
      if (segment.chapterFingerprint === chapterFingerprint) {
        setSelectedIdxSegments(prev =>
          prev.findIndex(item => item.segmentIdx === segmentIdx) === -1
            ? prev.concat({ segmentIdx, chapterFingerprint }).sort((a, b) => a.segmentIdx - b.segmentIdx)
            : prev.filter(item => item.segmentIdx !== segmentIdx).sort((a, b) => a.segmentIdx - b.segmentIdx),
        );
      }
    }
  }

  async function handlePlayAtPosition(currentTime: number, idx: number) {
    async function mediaPlay(media: HTMLVideoElement) {
      try {
        await media.play();
        setCurrentlyPlayingVideo(idx);
        setIsPaused(false);
      } catch (err) {
        media.pause();
        setIsPaused(true);
        handleStopClicked();
      }
    }

    if (!knownVideos.length) return;

    const media = knownVideos[idx];
    if (!media) return;

    if (currentlyPlayingVideo === null) {
      media.element.currentTime = currentTime;
      mediaPlay(media.element);
      return;
    }

    const playingMedia = knownVideos[currentlyPlayingVideo];
    if (!playingMedia) return;

    setIsPaused(true);
    stopNextPlay();
    resetPlaybackPositionForAll(false);
    await new Promise(resolve => setTimeout(resolve, 300));
    setupNextPlayAfterStop();

    handlePlayClicked(idx, currentTime);

    // setIsPaused(true);
    // media.element.pause();
    // playingMedia.element.pause();

    // resetPlaybackPositionForAll(false);
    // await new Promise(resolve => setTimeout(resolve, 300));
    // setupNextPlayAfterStop();

    // media.element.currentTime = currentTime;
    // mediaPlay(media.element);
  }

  function showPlayerTimeLineThumb(idx: number | null) {
    if (!controlsRef.current) return;

    const timeLineThumbDOM = controlsRef.current.querySelectorAll<HTMLElement>(
      '.control__timeline .control__timeline__thumb',
    );
    if (!timeLineThumbDOM.length) return;

    timeLineThumbDOM.forEach(el => {
      el.style.opacity = '0';
      if (idx !== null && el.classList.contains('thumb-' + idx)) {
        el.style.opacity = '1';
      }
    });
  }

  return (
    <div style={{ flex: 1, display: 'flex', justifyContent: !isModeVideoEdit ? 'center' : 'flex-start' }}>
      <div id="assembly" className={`${!areColumnsOpen ? 'none-columns' : ''} ${isModeVideoEdit ? 'edit-mode' : ''}`}>
        <Container className="assembly-container">
          <Row>
            <Col className={!isModeVideoEdit ? `assembly-col-1` : 'video-edit-mode'}>
              <div className="video-container" ref={videoContainerRef}>
                <div className="canvas-player">
                  <div ref={canvasPlayerRef}></div>
                  <canvas id="canvas-player" ref={canvasRef}>
                    Please update your browser.
                  </canvas>
                  <div ref={controlsRef} className="controls">
                    <div className="control__timeline">{renderSegmentsTimeLine}</div>
                    <div className="control__button">
                      {isModeVideoEdit && (
                        <span className="control__button__current-time-and-duration">
                          <span ref={currentTimePlayerRef}>00:00:00</span>
                          <span>/</span>
                          <span>{getFormatDurationAllSegments}</span>
                        </span>
                      )}

                      <Button onClick={handleBackwardClicked} className="mr-1 btn btn-simon">
                        <FontAwesomeIcon icon={faStepBackward} width={14} height={16} />
                      </Button>
                      {isPaused && (
                        <Button onClick={() => handlePlayClicked()} className="mr-1 btn btn-simon">
                          <FontAwesomeIcon icon={faPlay} width={14} height={16} />
                        </Button>
                      )}
                      {!isPaused && (
                        <Button onClick={handlePauseClicked} className="mr-1 btn btn-simon">
                          <FontAwesomeIcon icon={faPause} width={14} height={16} />
                        </Button>
                      )}
                      <Button onClick={handleStopClicked} className="mr-1 btn btn-simon">
                        <FontAwesomeIcon icon={faStop} width={14} height={16} />
                      </Button>
                      <Button onClick={handleForwardClicked} className="mr-1 btn btn-simon">
                        <FontAwesomeIcon icon={faStepForward} width={14} height={16} />
                      </Button>
                    </div>
                    <div className="control__playbackspeed">
                      <UncontrolledDropdown>
                        <DropdownToggle>{currenPlayBackSpeed}x</DropdownToggle>
                        <DropdownMenu>
                          {Object.keys(PlaybackSpeed).map((playbackSpeed: string) => (
                            <DropdownItem
                              key={'playbackSpeed-' + playbackSpeed}
                              onClick={() => handleChangePlaybackSpeed(playbackSpeed)}
                              active={currenPlayBackSpeed === PlaybackSpeed[playbackSpeed as PlaybackSpeedType]}
                            >
                              {playbackSpeed}
                            </DropdownItem>
                          ))}
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  </div>
                </div>
                {!isModeVideoEdit && areColumnsOpen && (
                  <div style={{ fontSize: 11 }}>{getFormatDurationAllSegments}</div>
                )}
              </div>
              <div style={{ display: isModeVideoEdit ? 'none' : 'block' }} ref={cardHolder} className="card-holder">
                <DndProvider backend={HTML5Backend}>
                  {renderCards}
                  <DraggablePreview selectedIdxSegments={selectedIdxSegments} />
                </DndProvider>
              </div>
            </Col>
            {/* <Col xs="1" sm="2" md="5" className="assembly-col-2"></Col> */}
          </Row>
        </Container>
      </div>

      {isModeVideoEdit && (
        <TimelineEditor
          cardHolder={cardHolder}
          segments={segments}
          currentlyPlayingVideo={currentlyPlayingVideo}
          isPaused={isPaused}
          getFormatDurationAllSegments={getFormatDurationAllSegments}
          moveSegment={moveSegment}
          globalCurrentTimeRef={globalCurrentTimeRef}
          handleInsertTransition={handleInsertTransition}
          handlePlayAtPosition={handlePlayAtPosition}
          showPlayerTimeLineThumb={showPlayerTimeLineThumb}
          listLanguageRegionCode={listLanguageRegionCode}
          forwardPlayerRef={canvasPlayerRef}
          handlePlayAtIndex={(index: number) => handleStopAndPlayMedia(index)}
        />
      )}
    </div>
  );
}

function getItemStyles(initialOffset: any, currentOffset: any) {
  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }
  let { x, y } = currentOffset;
  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

function DraggablePreview({ selectedIdxSegments }: any) {
  const { itemType, isPreviewDragging, item, initialOffset, currentOffset } = useDragLayer(
    (monitor: DragLayerMonitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      currentOffset: monitor.getSourceClientOffset(),
      isPreviewDragging: monitor.isDragging(),
    }),
  );

  if (!isPreviewDragging || itemType !== ItemTypes.CHAPTER_ITEM || selectedIdxSegments.length <= 1) return null;

  return (
    <div className="card-preview">
      <div style={{ ...getItemStyles(initialOffset, currentOffset), position: 'relative' }}>
        <div className="card-preview__item" style={{ transform: 'rotate(1deg)' }}>
          Multiple items
        </div>
        <div
          className="card-preview__item"
          style={{ transform: 'rotate(-1deg)', position: 'absolute', left: 0, right: 0, top: 0 }}
        >
          Multiple items
        </div>
      </div>
    </div>
  );
}

function DraggableSegment({
  cardHolderRef,
  isLastChapter,
  isLoadMedia,
  isTimeOut,
  index,
  chapterIdx,
  segment,
  url,
  handleRemoveClicked,
  currentlyPlayingVideo,
  readOnly,
  handleSegmentClicked,
  startTimecodeInSeconds,
  handleVideoThumbnailClicked,
  handleMediaCanPlay,
  moveSegment,
  isLastItemInChapter,
  chapterFingerprint,
  moveChapter,
  chapterCount,
  handleAddChapter,
  selectSegments,
  selectedIdxSegments,
  justDroppedChapter,
}: any) {
  const ref = useRef<HTMLDivElement | null>(null);
  const segmentRef = useRef<HTMLDivElement | null>(null);
  const { isPreviewDragging } = useDragLayer((monitor: DragLayerMonitor) => ({
    isPreviewDragging: monitor.isDragging(),
  }));
  const [isOverTop, setIsOverTop] = useState(false);
  const isPressedModifierKey = useRef(false);
  const [isPlayAmination, setIsPlayAmination] = useState(false);

  useLayoutEffect(() => {
    const isJustDroppedChapter = justDroppedChapter.current?.fingerprint === chapterFingerprint;
    let timeOut: any = null;

    setIsPlayAmination(isPlayed => {
      if (!isPlayed && isJustDroppedChapter) return true;
      return false;
    });

    if (isJustDroppedChapter) {
      timeOut = setTimeout(() => {
        setIsPlayAmination(false);
      }, 1200); // time to remove class of animation
    }

    return () => {
      if (timeOut) clearTimeout(timeOut);
      setIsPlayAmination(false);
    };
  }, [justDroppedChapter.current]);

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CHAPTER_ITEM,
    hover(item: any, monitor: DropTargetMonitor) {
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();

      if (!hoverBoundingRect) return false;
      if (!clientOffset) return false;

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      setIsOverTop(hoverClientY < hoverMiddleY);
    },
    drop(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const prevIndex = item.index;
      const nextIndex = index;
      const prevChapterIdx = item.chapterIdx;
      const nextChapterIdx = chapterIdx;
      const isUpwards = prevIndex > nextIndex;
      let num = 0;

      if (prevIndex === nextIndex) return;

      if (prevChapterIdx !== nextChapterIdx) {
        if (selectedIdxSegments.length <= 1) {
          if (isUpwards && !isOverTop) num = 1;
          if (!isUpwards && isOverTop) num = -1;
        } else {
          if (isUpwards && isOverTop) num = -1;
          if (!isUpwards && isOverTop) num = -1;
        }
      } else {
        if (selectedIdxSegments.length <= 1) {
          if (isUpwards && !isOverTop && prevIndex === nextIndex + 1) return;
          if (!isUpwards && isOverTop && prevIndex === nextIndex - 1) return;
        } else {
          if (isUpwards && isOverTop) num = -1;
        }
      }

      moveSegment(prevChapterIdx, nextChapterIdx, prevIndex, nextIndex + num);
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver() && (monitor.getItem() as any).index !== index,
    }),
  });

  const [{ isBottomOver }, dropChapterBottomRef] = useDrop({
    accept: ItemTypes.CHAPTER,
    drop(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const prevIndex = item.index;
      const nextIndex = index;

      if (prevIndex === nextIndex) return;

      moveChapter(prevIndex, nextIndex, true);
    },
    collect: monitor => ({
      isBottomOver: monitor.isOver(),
    }),
  });

  const [{ isDragging, draggingItem }, drag, preview] = useDrag(
    {
      type: ItemTypes.CHAPTER_ITEM,
      item: { index, chapterIdx },
      canDrag() {
        return !readOnly;
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
        draggingItem: monitor.getItem(),
      }),
    },
    [index, chapterIdx, readOnly],
  );

  useEffect(() => {
    if (selectedIdxSegments.length >= 2) preview(getEmptyImage(), { captureDraggingState: true });
    else preview(null);
  }, [selectedIdxSegments]);

  useLayoutEffect(() => {
    drag(drop(ref));
    return () => {
      drag(null);
      drop(null);
    };
  }, [drag, drop, ref]);

  useLayoutEffect(() => {
    function selectSegment(event: MouseEvent) {
      isPressedModifierKey.current = event.ctrlKey || event.metaKey;
      if (isPressedModifierKey.current) {
        selectSegments(index, chapterFingerprint);
      } else {
        selectSegments(null, chapterFingerprint);
      }
    }

    function removeSelectionSegments(event: MouseEvent) {
      isPressedModifierKey.current = event.ctrlKey || event.metaKey;
      if (!isPressedModifierKey.current && selectedIdxSegments.length) {
        selectSegments(null, chapterFingerprint);
      }
    }

    if (segmentRef.current) {
      segmentRef.current.addEventListener('click', selectSegment);
      document.body.addEventListener('click', removeSelectionSegments);
    }

    return () => {
      if (segmentRef.current) {
        segmentRef.current.removeEventListener('click', selectSegment);
        document.body.removeEventListener('click', removeSelectionSegments);
      }
    };
  }, [index, chapterFingerprint, selectedIdxSegments]);

  function canView() {
    if (!cardHolderRef.current || !segmentRef.current) return true;

    const cardHolderRect = cardHolderRef.current.getBoundingClientRect();
    const segmentRect = segmentRef.current.getBoundingClientRect();
    const boxView = Math.round(cardHolderRect.bottom);
    const segmentBottom = Math.round(segmentRect.bottom);
    return segmentBottom <= boxView + 12 && segmentBottom >= cardHolderRect.top;
  }

  const isSelected = selectedIdxSegments.findIndex((item: any) => item.segmentIdx === index) !== -1;
  const opacity = isDragging || (isPreviewDragging && selectedIdxSegments.length >= 2 && isSelected) ? 0.5 : 1;

  let isUpwards = false;
  let isDownwards = false;

  const isSameChapter = chapterIdx === draggingItem?.chapterIdx;
  if (isSameChapter) {
    isUpwards = isOver && isOverTop;
    isDownwards = isOver && !isOverTop;
  } else {
    isUpwards = isOver && isOverTop;
    isDownwards = isOver && !isOverTop;
  }

  return (
    <div
      style={{ opacity }}
      className={`
        card-item-holder segment-holder 
        ${!isLastItemInChapter ? 'last-item-holder' : 'not-last-item-holder'} 
        ${isSelected ? 'selected' : ''} ${isOver ? 'over' : ''}
        ${chapterIdx === null ? 'no-chapter' : ''}
        ${isPlayAmination ? 'drop-chapter-item-amination' : ''}
      `}
      data-chapter={chapterFingerprint}
    >
      <div>
        {!readOnly && (
          <div className="card-item-controls">
            <span title="Add a new chapter here" onClick={() => handleAddChapter('Untitled Chapter', index)}>
              <FontAwesomeIcon icon={faPlusCircle} width={10} height={10} />
            </span>
          </div>
        )}
        <div
          ref={ref}
          style={{
            borderTop: isUpwards ? '4px solid #fff' : '',
            borderBottom: isDownwards ? '4px solid #fff' : '',
            position: 'relative',
          }}
        >
          <SegmentCard
            ref={segmentRef}
            canView={canView()}
            isLoadMedia={isLoadMedia}
            isTimeOut={isTimeOut}
            index={index}
            segment={segment}
            url={url}
            handleRemoveClicked={handleRemoveClicked}
            isCurrent={currentlyPlayingVideo === index - chapterCount}
            readOnly={readOnly}
            handleSegmentClicked={handleSegmentClicked}
            startTimecodeInSeconds={startTimecodeInSeconds}
            handleVideoThumbnailClicked={() =>
              !isPressedModifierKey.current && handleVideoThumbnailClicked(chapterCount)
            }
            handleMediaCanPlay={handleMediaCanPlay}
          />
        </div>
        {!readOnly && isLastItemInChapter && (
          <div className="card-item-controls bottom">
            <span title="Add a new chapter here" onClick={() => handleAddChapter('Untitled Chapter', index + 1)}>
              <FontAwesomeIcon icon={faPlusCircle} width={10} height={10} />
            </span>
          </div>
        )}
      </div>
      {isLastChapter && isLastItemInChapter && (
        <div
          ref={dropChapterBottomRef}
          className={`line-holder ${(isBottomOver && 'show') || ''}`}
          style={{ margin: 0, alignItems: 'flex-start' }}
        >
          <div className="horizontal-line"></div>
        </div>
      )}
    </div>
  );
}

function DraggableChapter({
  index,
  segment,
  isLastChapter,
  numOfChapterItem,
  hasChildrenInChapter,
  moveChapter,
  editChapterName,
  removeChapter,
  moveSegment,
  cardHolderRef,
  readOnly,
  handleAddChapter,
  isJustCreatedChapter,
  justDroppedChapter,
}: any) {
  const { text, fingerprint } = segment;
  const inputChapterName = useTextInputSettable(text);
  const inputChapterNameRef = useRef<HTMLInputElement | null>(null);
  const [isOpen, setIsOpen] = useState(true);
  const prevTempIsOpen = useRef(true);
  const [isPlayAmination, setIsPlayAmination] = useState(false);

  useLayoutEffect(() => {
    const isJustDroppedChapter = justDroppedChapter.current?.fingerprint === fingerprint;
    let timeOut: any = null;

    setIsPlayAmination(isPlayed => {
      if (!isPlayed && isJustDroppedChapter) return true;
      return false;
    });

    if (isJustDroppedChapter) {
      timeOut = setTimeout(() => {
        setIsPlayAmination(false);
        justDroppedChapter.current = null;
      }, 1000); // time to remove class of animation
    }

    return () => {
      if (timeOut) clearTimeout(timeOut);
      setIsPlayAmination(false);
    };
  }, [justDroppedChapter.current]);

  const ref = useRef<HTMLDivElement | null>(null);
  const [{ isChapterOver }, drop] = useDrop({
    accept: ItemTypes.CHAPTER_ITEM,
    drop(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const prevIndex = item.index;
      const nextIndex = index;
      const prevChapterIdx = item.chapterIdx;
      const nextChapterIdx = index;
      const isUpwards = prevIndex > nextIndex;

      if (prevIndex === nextIndex || item.chapterIdx === nextIndex) return;

      moveSegment(prevChapterIdx, nextChapterIdx, prevIndex, nextIndex + (isUpwards ? 1 : 0), true);
    },
    collect: monitor => ({
      isChapterOver: monitor.isOver(),
    }),
  });

  const [{ isTopOver }, dropChapterTopRef] = useDrop({
    accept: ItemTypes.CHAPTER,
    drop(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) return;

      const prevIndex = item.index;
      const nextIndex = index;
      if (prevIndex === nextIndex) return;

      moveChapter(prevIndex, nextIndex);
    },
    collect: monitor => ({
      isTopOver: monitor.isOver(),
    }),
  });

  const [{ isBottomOver }, dropChapterBottomRef] = useDrop({
    accept: ItemTypes.CHAPTER,
    drop(item: any, monitor: DropTargetMonitor) {
      if (!ref.current) return;
      const prevIndex = item.index;
      const nextIndex = index;

      if (prevIndex === nextIndex) return;

      moveChapter(prevIndex, nextIndex, true);
    },
    collect: monitor => ({
      isBottomOver: monitor.isOver(),
    }),
  });

  const [{ isDragging }, drag] = useDrag(
    {
      type: ItemTypes.CHAPTER,
      item: { index },
      canDrag() {
        return !readOnly;
      },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [index, readOnly],
  );

  useLayoutEffect(() => {
    drag(drop(ref));

    return () => {
      drag(null);
      drop(null);
    };
  }, [drag, drop, ref]);

  useLayoutEffect(() => {
    if (cardHolderRef.current) {
      const nodeItems = (cardHolderRef.current as HTMLElement).querySelectorAll<HTMLElement>(
        `[data-chapter="${fingerprint}"]`,
      );

      for (let idx = 0; idx < nodeItems.length; idx++) {
        if (!isOpen) {
          nodeItems[idx].style.display = 'none';
        } else {
          nodeItems[idx]!.style.display = 'block';
        }
      }
    }

    return () => {
      if (cardHolderRef.current) {
        // bring back chapter items when its chapter is removed.
        const nodeItems = (cardHolderRef.current as HTMLElement).querySelectorAll<HTMLElement>(
          `[data-chapter="${fingerprint}"]`,
        );
        for (let idx = 0; idx < nodeItems.length; idx++) {
          nodeItems[idx]!.style.display = 'block';
        }
      }
    };
  }, [isOpen, cardHolderRef, numOfChapterItem]);

  useEffect(() => {
    if (isJustCreatedChapter) inputChapterNameRef.current?.select();
  }, [isJustCreatedChapter]);

  useEffect(() => {
    if (isDragging) {
      setIsOpen(prev => {
        prevTempIsOpen.current = prev;
        return false;
      });
    }
    if (!isDragging) {
      setIsOpen(prevTempIsOpen.current);
    }
  }, [isDragging]);

  function toggle() {
    setIsOpen(isOpen => !isOpen);
  }

  function renameChapter() {
    if (inputChapterName.value !== text) editChapterName(index, inputChapterName.value);
  }

  const opacity = isDragging ? 0 : 1;
  const isShowLineHolderBottom = isLastChapter && (!isOpen || !hasChildrenInChapter);

  return (
    <div
      className={`card-chapter segment-holder ${isPlayAmination ? 'drop-chapter-amination' : ''}`}
      style={{ opacity }}
    >
      <div ref={dropChapterTopRef} className={`line-holder ${(isTopOver && 'show') || ''}`}>
        <div className="horizontal-line"></div>
      </div>
      <div
        ref={ref}
        className={`card-chapter-holder ${(isChapterOver && 'over') || ''} ${
          isShowLineHolderBottom ? 'no-margin' : ''
        }`}
        onClick={toggle}
      >
        {!readOnly && (
          <div className="card-chapter-controls">
            <span
              title="Add a new chapter here"
              onClick={e => {
                e.stopPropagation();
                handleAddChapter('Untitled Chapter', index);
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} width={10} height={10} />
            </span>
          </div>
        )}
        <div style={{ flex: 1 }} className="card-chapter-name">
          <span className={`icon-draggable ${isDragging ? 'dragging' : ''}`}>
            <FontAwesomeIcon icon={faGripVertical} width={14} height={14} />
          </span>
          <span style={{ position: 'relative', top: -2, marginRight: 4 }}>
            <FontAwesomeIcon icon={faPollH} width={14} height={14} />
          </span>
          <input
            ref={inputChapterNameRef}
            style={{ width: '40%' }}
            value={inputChapterName.value}
            onClick={e => e.stopPropagation()}
            onChange={inputChapterName.onChange}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                renameChapter();
                if (inputChapterNameRef.current) inputChapterNameRef.current.blur();
              }
            }}
            onBlur={() => renameChapter()}
            disabled={readOnly}
          />
        </div>
        <div className="btn-chapter">
          {/* <div className="chapter-items-count">
            {numOfChapterItem}
          </div> */}
          {!readOnly && (
            <button
              className="btn-trash"
              onClick={e => {
                e.stopPropagation();
                removeChapter(index);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} width={14} height={14} />
            </button>
          )}
        </div>
        {!readOnly && numOfChapterItem === 0 && (
          <div className="card-chapter-controls bottom">
            <span
              title="Add a new chapter here"
              onClick={e => {
                e.stopPropagation();
                handleAddChapter('Untitled Chapter', index + 1);
              }}
            >
              <FontAwesomeIcon icon={faPlusCircle} width={10} height={10} />
            </span>
          </div>
        )}
      </div>

      {isShowLineHolderBottom && (
        <div ref={dropChapterBottomRef} className={`line-holder bottom ${(isBottomOver && 'show') || ''}`}>
          <div className="horizontal-line"></div>
        </div>
      )}
    </div>
  );
}

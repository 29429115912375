import React from 'react';

const IconRemoveCaption = () => {
  return (
    <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.23438 8.375C5.07812 8.375 5 8.29688 5 8.14062V3.92188C5 3.76562 5.07812 3.6875 5.23438 3.6875H5.70312C5.85938 3.6875 5.9375 3.76562 5.9375 3.92188V8.14062C5.9375 8.29688 5.85938 8.375 5.70312 8.375H5.23438ZM8.4375 1.8125C8.52865 1.8125 8.60026 1.84505 8.65234 1.91016C8.71745 1.96224 8.75 2.03385 8.75 2.125V2.4375C8.75 2.52865 8.71745 2.60677 8.65234 2.67188C8.60026 2.72396 8.52865 2.75 8.4375 2.75H8.125V9.3125C8.125 9.57292 8.03385 9.79427 7.85156 9.97656C7.66927 10.1589 7.44792 10.25 7.1875 10.25H1.5625C1.30208 10.25 1.08073 10.1589 0.898438 9.97656C0.716146 9.79427 0.625 9.57292 0.625 9.3125V2.75H0.3125C0.221354 2.75 0.143229 2.72396 0.078125 2.67188C0.0260417 2.60677 0 2.52865 0 2.4375V2.125C0 2.03385 0.0260417 1.96224 0.078125 1.91016C0.143229 1.84505 0.221354 1.8125 0.3125 1.8125H1.91406L2.57812 0.699219C2.76042 0.39974 3.03385 0.25 3.39844 0.25H5.35156C5.71615 0.25 5.98958 0.39974 6.17188 0.699219L6.83594 1.8125H8.4375ZM3.35938 1.24609L3.00781 1.8125H5.74219L5.39062 1.24609C5.36458 1.20703 5.33203 1.1875 5.29297 1.1875H3.45703C3.41797 1.1875 3.38542 1.20703 3.35938 1.24609ZM7.1875 9.3125V2.75H1.5625V9.3125H7.1875ZM3.04688 8.375C2.89062 8.375 2.8125 8.29688 2.8125 8.14062V3.92188C2.8125 3.76562 2.89062 3.6875 3.04688 3.6875H3.51562C3.67188 3.6875 3.75 3.76562 3.75 3.92188V8.14062C3.75 8.29688 3.67188 8.375 3.51562 8.375H3.04688Z"
        fill="#8F9BB3"
      />
    </svg>
  );
};

export default IconRemoveCaption;

import React from 'react';

const IconVisibility = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.12109 3.12109C4.36849 2.8737 4.66146 2.75 5 2.75C5.33854 2.75 5.63151 2.8737 5.87891 3.12109C6.1263 3.36849 6.25 3.66146 6.25 4C6.25 4.33854 6.1263 4.63151 5.87891 4.87891C5.63151 5.1263 5.33854 5.25 5 5.25C4.66146 5.25 4.36849 5.1263 4.12109 4.87891C3.8737 4.63151 3.75 4.33854 3.75 4C3.75 3.66146 3.8737 3.36849 4.12109 3.12109ZM3.51562 5.48438C3.93229 5.88802 4.42708 6.08984 5 6.08984C5.57292 6.08984 6.0612 5.88802 6.46484 5.48438C6.88151 5.06771 7.08984 4.57292 7.08984 4C7.08984 3.42708 6.88151 2.9388 6.46484 2.53516C6.0612 2.11849 5.57292 1.91016 5 1.91016C4.42708 1.91016 3.93229 2.11849 3.51562 2.53516C3.11198 2.9388 2.91016 3.42708 2.91016 4C2.91016 4.57292 3.11198 5.06771 3.51562 5.48438ZM2.20703 1.73438C3.04036 1.16146 3.97135 0.875 5 0.875C6.02865 0.875 6.95964 1.16146 7.79297 1.73438C8.6263 2.30729 9.22526 3.0625 9.58984 4C9.22526 4.9375 8.6263 5.69271 7.79297 6.26562C6.95964 6.83854 6.02865 7.125 5 7.125C3.97135 7.125 3.04036 6.83854 2.20703 6.26562C1.3737 5.69271 0.77474 4.9375 0.410156 4C0.77474 3.0625 1.3737 2.30729 2.20703 1.73438Z"
        fill="#8F9BB3"
      />
    </svg>
  );
};

export default IconVisibility;

import React, { useState, useEffect, useRef, useMemo, useCallback, useContext, useLayoutEffect } from 'react';
import moment from 'moment';
import { DndProvider, DropTargetMonitor, useDrag, useDrop, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import Thread from '../popover/Thread';
import ThreadPopover from '../popover/ThreadPopover';
import {
  TimeLineTool1,
  TimeLineTool2,
  TimeLineTool3,
  TimeLineTool4,
  TimeLineTool5,
  TimeLineTool6,
  TimeLineTool7,
} from '../svg/TimeLineTools';
import IconVisibility from '../svg/IconVisibility';
import IconInvisibility from '../svg/IconInvisibility';
import IconRemoveCaption from '../svg/IconRemoveCaption';
import { useAuth0 } from '@auth0/auth0-react';

const MILLISECONDS_PER_PIXEL = 100;
const PIXEL_PER_MILLISECONDS = 1;
const NUM_OFFSET_MOUSE_PIXEL = 0;
const STEP_TIME_RULER_IN_SECONDS = 1;
const START_TIME_IN_TIME_RULER = 0;
let END_TIME_IN_TIME_RULER = 5 * 60;
const PIXEL_PER_UNIT_SCALE = 50;
const NUMBER_OF_SHORT_RULER_BETWEEN = 10;
const OFFSET_MOVE_SCROLLBAR_AUTO = 30;

enum ItemTypes {
  CHAPTER_ITEM = 'CHAPTER_ITEM',
  CHAPTER = 'CHAPTER',
}

interface DragItem {
  chapterIdx: number;
  index: number;
}

type ToolbarType = {
  isOpenTransition: boolean;
};

function generateArray(start: number, end: number, step: number) {
  const len = Math.floor((end - start) / step);
  return [...Array.from({ length: len }, (_, key: number) => key * step), end];
}

function generateFrom(start: number, end: number, step: number) {
  const len = Math.floor((end - start) / step);
  return [...Array.from({ length: len }, (_, key: number) => start + key * step)];
}

function getFormatDuration(milliseconds: number) {
  const result = moment.duration(milliseconds, 'milliseconds');
  return `${formatTwoDigits(result.hours())}:${formatTwoDigits(result.minutes())}:${formatTwoDigits(result.seconds())}`;
}

function formatTwoDigits(num: number): string {
  return num.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

function convertMillisecondToPixel(milliseconds: number, scale: number) {
  const ratio = PIXEL_PER_MILLISECONDS / scale;
  return (milliseconds * ratio) / MILLISECONDS_PER_PIXEL;
}

function convertPixelToMilliseconds(pixel: number, scale: number, isMouseEvent: boolean = false) {
  let numDiffMousePixel = 0;
  const ratio = PIXEL_PER_MILLISECONDS / scale;

  if (isMouseEvent) numDiffMousePixel = NUM_OFFSET_MOUSE_PIXEL * (MILLISECONDS_PER_PIXEL / ratio);
  return (pixel * MILLISECONDS_PER_PIXEL) / ratio - numDiffMousePixel;
}

type TimelineEditorProps = {
  forwardPlayerRef: any;
  segments: Segment[];
  cardHolder: React.RefObject<HTMLDivElement>;
  currentlyPlayingVideo: number | null;
  isPaused: boolean;
  getFormatDurationAllSegments: string | null;
  globalCurrentTimeRef: any;
  listLanguageRegionCode?: string[];
  handleInsertTransition: (index: number, segment: Segment) => void;
  handlePlayAtPosition: (time: number, idx: number) => void;
  moveSegment: (
    prevChapterIdx: number,
    nextChapterIdx: number,
    prevIndex: number,
    nextIndex: number,
    isDroppedOverChapter: boolean,
  ) => void;
  showPlayerTimeLineThumb: (idx: number | null) => void;
  handlePlayAtIndex: (index: number) => void;
};

export default function TimelineEditor({
  forwardPlayerRef,
  segments,
  cardHolder,
  currentlyPlayingVideo,
  isPaused,
  getFormatDurationAllSegments,
  globalCurrentTimeRef,
  listLanguageRegionCode,
  handleInsertTransition,
  handlePlayAtPosition,
  moveSegment,
  showPlayerTimeLineThumb,
  handlePlayAtIndex,
}: TimelineEditorProps) {
  const timeLineContainerRef = useRef<HTMLDivElement>(null);
  const [isExpandLayoutView, setIsExpandLayoutView] = useState<boolean>(false);
  const diffTime = (startTime: number, endTime: number) => {
    const duration = moment.duration(String(endTime - startTime), 'seconds') as Duration;
    return duration.asSeconds();
  };
  const [globalCurrentTime, setGlobalCurrentTime] = useState<number | null>(null);

  const segmentsAfterNormalize = useMemo(() => {
    let startNew = 0;
    let endNew = 0;
    let totalDuaration = 0;

    const lastChapterIdx = segments.map((segment: Segment) => segment.is_chapter).lastIndexOf(true);
    let isLastChapter = false;
    let currentChapterIdx: null | number = null;
    let currentChapterFingerprint: null | String = null;
    let chapterCount = 0;
    let newStartTimeChapter: null | number = null;
    let newEndTimeChapter: null | number = null;

    END_TIME_IN_TIME_RULER = 5 * 60;

    const result = segments.map((segment: Segment, index) => {
      const duration = diffTime(segment.start, segment.end);
      totalDuaration += duration;

      if (index <= 0) {
        startNew = 0;
        endNew = duration;
      } else {
        startNew = endNew;
        endNew = startNew + duration;
      }

      if (segment.is_chapter) {
        currentChapterIdx = index;
        currentChapterFingerprint = segments[currentChapterIdx].fingerprint;
        chapterCount += 1;

        let nextIdx = index + 1;
        let nextSegment = segments[nextIdx];

        if (nextSegment && !nextSegment?.is_chapter) {
          const duration = diffTime(nextSegment.start, nextSegment.end);

          if (chapterCount > 1) {
            newStartTimeChapter = newEndTimeChapter;
            newEndTimeChapter = (newStartTimeChapter as number) + duration;
          } else {
            newStartTimeChapter = 0;
            newEndTimeChapter = duration;
          }

          while (nextSegment && !nextSegment?.is_chapter) {
            nextIdx++;
            nextSegment = segments[nextIdx];

            if (!nextSegment) break;
            if (nextSegment?.is_chapter) break;

            const duration = diffTime(nextSegment.start, nextSegment.end);
            newEndTimeChapter += duration;
          }
        }
      }

      if (!isLastChapter && lastChapterIdx === index) isLastChapter = true;

      return {
        ...segment,
        difftime: duration,
        startNew: startNew,
        endNew: endNew,
        chapterCount,
        currentChapterFingerprint,
        chapterIdx: currentChapterIdx,
        newStartTimeChapter,
        newEndTimeChapter,
      };
    });

    END_TIME_IN_TIME_RULER += totalDuaration;
    return result;
  }, [segments]);

  useEffect(() => {
    globalCurrentTimeRef.current = { updateGlobalTime };
  }, []);

  useEffect(() => {
    if (timeLineContainerRef.current) {
      if (isExpandLayoutView) {
        timeLineContainerRef.current.style.left = '320px';
      } else {
        timeLineContainerRef.current.style.left = '0';
      }
    }
  }, [isExpandLayoutView]);

  function expandLayoutView() {
    setIsExpandLayoutView(!isExpandLayoutView);
  }

  function updateGlobalTime(time: number | null) {
    setGlobalCurrentTime(time);
  }

  return (
    <div id="time-line-container" ref={timeLineContainerRef}>
      <React.Fragment>
        <TimeLineContainer
          forwardPlayerRef={forwardPlayerRef}
          cardHolder={cardHolder}
          segmentsAfterNormalize={segmentsAfterNormalize}
          currentlyPlayingVideo={currentlyPlayingVideo}
          getFormatDurationAllSegments={getFormatDurationAllSegments}
          isPaused={isPaused}
          globalCurrentTime={globalCurrentTime}
          listLanguageRegionCode={listLanguageRegionCode}
          isExpandLayoutView={isExpandLayoutView}
          expandLayoutView={expandLayoutView}
          moveSegment={moveSegment}
          handleInsertTransition={handleInsertTransition}
          handlePlayAtPosition={handlePlayAtPosition}
          showPlayerTimeLineThumb={showPlayerTimeLineThumb}
          handlePlayAtIndex={handlePlayAtIndex}
        />
      </React.Fragment>
    </div>
  );
}

function TimeLineContainer({
  forwardPlayerRef,
  segmentsAfterNormalize,
  cardHolder,
  moveSegment,
  currentlyPlayingVideo,
  getFormatDurationAllSegments,
  isPaused,
  globalCurrentTime,
  expandLayoutView,
  isExpandLayoutView,
  handleInsertTransition,
  handlePlayAtPosition,
  listLanguageRegionCode,
  showPlayerTimeLineThumb,
  handlePlayAtIndex,
}: any) {
  const [scale, setScale] = useState(1);
  const timeLineWorkplaceRef = useRef<null | HTMLDivElement>(null);
  const timeLineMainRef = useRef<null | HTMLDivElement>(null);
  const currentTimeHoverRef = useRef<null | HTMLElement>(null);
  const seekbarRef = useRef<null | HTMLDivElement>(null);
  const scrollBarWidth = useRef<null | number>(null);
  const [toolbar, setToolbar] = useState<ToolbarType>({ isOpenTransition: false });
  const currentMilisecond = useRef<number>(0);

  const [visibilityVideoTrack, setVisibilityVideoTrack] = useState(true);

  const isDragSeekbar = useRef(false);
  const forcePause = useRef<Boolean>(false);
  const [anchorTimeRuler, setAnchorTimeRuler] = useState<number[]>(
    // generateArray(START_TIME_IN_TIME_RULER, END_TIME_IN_TIME_RULER, STEP_TIME_RULER_IN_SECONDS)
    [],
  );

  const sectionOneRef = useRef<HTMLDivElement>(null);
  const sectionTwoRef = useRef<HTMLDivElement>(null);
  const windowWidhtRef = useRef<number>(window.innerWidth);

  useEffect(() => {
    function reportWindowSize() {
      const popoverInnerEl = document.querySelector<HTMLDivElement>('.thread.edit-mode.show .popover-inner');
      if (!popoverInnerEl) return;

      const { width: popoverInnerWidth } = popoverInnerEl.getBoundingClientRect();
      const minWidthPopover = 180;
      const maxWidthPopover = 230;
      const diffWidth = window.innerWidth - windowWidhtRef.current;

      let newWidth = popoverInnerWidth + diffWidth;
      newWidth = newWidth < minWidthPopover ? minWidthPopover : newWidth;
      newWidth = newWidth > maxWidthPopover ? maxWidthPopover : newWidth;

      popoverInnerEl.style.width = `${newWidth}px`;
      windowWidhtRef.current = window.innerWidth;
    }

    window.addEventListener('resize', reportWindowSize);

    return () => {
      window.removeEventListener('resize', reportWindowSize);
    };
  }, []);

  useEffect(() => {
    if (!isDragSeekbar.current && !isPaused) {
      updateSeekbar(globalCurrentTime);
    }
  }, [globalCurrentTime, isPaused, isDragSeekbar]);

  useEffect(() => {
    function calculateAnchorTimeRuler(scale: number) {
      const finalStep = STEP_TIME_RULER_IN_SECONDS + scale * 10;
      const newAnchorTimeRuler = generateArray(
        START_TIME_IN_TIME_RULER,
        END_TIME_IN_TIME_RULER + finalStep * scale * 2,
        finalStep,
      );

      return newAnchorTimeRuler;
    }

    setAnchorTimeRuler(calculateAnchorTimeRuler(scale));
  }, [scale]);

  useEffect(() => {
    let isDown = false;
    let isDropped = true;

    if (!timeLineWorkplaceRef.current || !seekbarRef.current || !currentTimeHoverRef.current) return;

    function onMousedown(event: MouseEvent) {
      if (!timeLineWorkplaceRef.current || !seekbarRef.current || !currentTimeHoverRef.current) return;
      isDown = true;
      isDropped = false;
    }

    function onMouseUp(event: MouseEvent) {
      isDown = false;
    }

    function onMouseMove(event: MouseEvent) {
      event.preventDefault();
      if (!timeLineWorkplaceRef.current || !seekbarRef.current || !currentTimeHoverRef.current) return;
      const seekbarEl = seekbarRef.current;

      if (isDown) {
        isDragSeekbar.current = true;
        const { offsetLeft, scrollLeft } = timeLineWorkplaceRef.current;
        const position: number = event.clientX - offsetLeft + scrollLeft;
        seekbarEl.style.left = `${position - NUM_OFFSET_MOUSE_PIXEL}px`;
        const milliseconds = convertPixelToMilliseconds(position, scale, true);
        currentTimeHoverRef.current.innerText = getFormatDuration(milliseconds < 0 ? 0 : milliseconds);
        currentMilisecond.current = milliseconds / 1000;
        updateSeekbar(currentMilisecond.current);
        const { idx } = findIdxSegmentPlay(currentMilisecond.current);
        showPlayerTimeLineThumb(idx);
      } else {
        if (!isDropped) {
          isDropped = true;
          handleClickLabelSeekbar(currentMilisecond.current);
          isDragSeekbar.current = false;
        }
        isDragSeekbar.current = false;
        showPlayerTimeLineThumb(null);
      }
    }

    seekbarRef.current.addEventListener('mousedown', onMousedown, false);
    document.addEventListener('mouseup', onMouseUp, false);
    timeLineWorkplaceRef.current.addEventListener('mousemove', onMouseMove, false);

    return () => {
      if (!timeLineWorkplaceRef.current || !seekbarRef.current) return;

      seekbarRef.current.removeEventListener('mousedown', onMousedown, false);
      document.removeEventListener('mouseup', onMouseUp, false);
      timeLineWorkplaceRef.current.removeEventListener('mousemove', onMouseMove, false);
    };
  }, [currentlyPlayingVideo]);

  function setAllValueToolbar(toolbarObj: ToolbarType, value: boolean = false) {
    const newToolbarObj: any = {};
    for (const [key, value] of Object.entries(toolbarObj)) {
      newToolbarObj[key] = false;
    }
    return newToolbarObj;
  }

  function toggleToolbar(type: string) {
    if (type === 'transition') {
      setToolbar(prev => {
        const nextVal = setAllValueToolbar(prev);

        return {
          ...nextVal,
          isOpenTransition: !prev.isOpenTransition,
        };
      });
    }
  }

  function updateSeekbar(timeInSeconds: number | null) {
    if (!seekbarRef.current || !currentTimeHoverRef.current || timeInSeconds === null) return;

    const position: number = convertMillisecondToPixel(timeInSeconds * 1000, scale);
    seekbarRef.current.style.left = `${position - NUM_OFFSET_MOUSE_PIXEL}px`;
    const milliseconds = convertPixelToMilliseconds(position, scale, true);
    currentTimeHoverRef.current.innerText = getFormatDuration(milliseconds < 0 ? 0 : milliseconds);
  }

  // function onSeekBarMove(event: React.MouseEvent<HTMLDivElement>) {
  // if (!timeLineWorkplaceRef.current || !seekbarRef.current || !currentTimeHoverRef.current || !isPaused) return;
  // const { offsetLeft, scrollLeft } = timeLineWorkplaceRef.current;
  // const position: number = event.clientX - offsetLeft + scrollLeft;
  // seekbarRef.current.style.left = `${position - NUM_OFFSET_MOUSE_PIXEL}px`;
  // const milliseconds = convertPixelToMilliseconds(position, scale, true);
  // currentTimeHoverRef.current.innerText = getFormatDuration(milliseconds < 0 ? 0 : milliseconds);
  // }

  function scrollToPercentage(percentageWidth: number) {
    if (scrollBarWidth.current === null || timeLineWorkplaceRef.current === null) return;

    const position: number = (percentageWidth / 100) * scrollBarWidth.current;
    timeLineWorkplaceRef.current.scrollTo(position, 0);
  }

  function scaleTo(numScale: number) {
    setScale(Math.round(numScale));
  }

  function findIdxSegmentPlay(time: number) {
    let interpolateSegmentIdx = null;
    let exactTime = null;

    segmentsAfterNormalize.forEach((segment: any, index: any) => {
      if (segment.startNew <= time && time <= segment.endNew) {
        const segment = segmentsAfterNormalize[index];
        interpolateSegmentIdx = index - segment.chapterCount;
        exactTime = +segment.start + (time - segment.startNew);
      }
    });

    return { idx: interpolateSegmentIdx, exactTime };
  }

  function handleClickLabelSeekbar(time: number) {
    const { idx, exactTime } = findIdxSegmentPlay(time);
    if (idx !== null) {
      handlePlayAtPosition(exactTime, idx);
      updateSeekbar(time);
    }
  }

  function handleClickRulerItem(time: number) {
    const { idx, exactTime } = findIdxSegmentPlay(time);
    if (idx !== null) {
      handlePlayAtPosition(exactTime, idx);
      updateSeekbar(time);
    }
  }

  const handleChangeVisibilityCaptionTrack = useCallback((value: any, idx: any) => {
    if (sectionOneRef.current !== null && sectionTwoRef.current !== null) {
      const captionTrackEl = sectionOneRef.current.querySelector(`.track-${idx}`);
      const audioTrackEl = sectionTwoRef.current.querySelector(`.audio-track-${idx}`);
      captionTrackEl?.classList.toggle('hidden-track');
      audioTrackEl?.classList.toggle('hidden-track');
    }
  }, []);

  function handleChangeVisibilityVideoTrack() {
    setVisibilityVideoTrack(!visibilityVideoTrack);
  }

  const renderCaptionTrack = useMemo(() => {
    return segmentsAfterNormalize.map((segment: any) => (
      <AudioTrack key={segment.id} segment={segment} scale={scale} />
    ));
  }, [segmentsAfterNormalize, scale]);

  const renderListCaptionTrack = useMemo(() => {
    if (listLanguageRegionCode === undefined) return;

    return listLanguageRegionCode.map((value: number, idx: number) => {
      return (
        <div key={idx} className={`time-line__track time-line__track__audio-track audio-track-${idx}`}>
          {renderCaptionTrack}
        </div>
      );
    });
  }, [listLanguageRegionCode, handleChangeVisibilityCaptionTrack, scale]);

  const renderListTitleCaptionTrack = useMemo(() => {
    if (listLanguageRegionCode === undefined) return;
    return listLanguageRegionCode.map((value: any, idx: any) => {
      return (
        <div className={`time-line__wrapper__section-1__items caption-track track-${idx}`} key={idx}>
          <div className="item">
            <div className="language-text">{value}</div>
            <div onClick={() => handleChangeVisibilityCaptionTrack(value, idx)} className="icon-container">
              <div className="icon-visibility">
                <IconVisibility />
              </div>
              <div className="icon-invisibility">
                <IconInvisibility />
              </div>
            </div>
            <IconRemoveCaption />
          </div>
        </div>
      );
    });
  }, [listLanguageRegionCode, handleChangeVisibilityCaptionTrack]);

  const renderVideoTrack = useMemo(() => {
    return segmentsAfterNormalize.map((segment: any, index: any) => (
      <VideoTrack
        key={index}
        index={index}
        isLastItem={index === segmentsAfterNormalize.length - 1}
        segment={segment}
        scale={scale}
        currentlyPlayingVideo={currentlyPlayingVideo}
        moveSegment={moveSegment}
        toolbar={toolbar}
        handleInsertTransition={handleInsertTransition}
        forwardPlayerRef={forwardPlayerRef}
        handlePlayAtIndex={handlePlayAtIndex}
      />
    ));
  }, [segmentsAfterNormalize, scale, currentlyPlayingVideo, toolbar, forwardPlayerRef]);

  const renderChapterLabel = useMemo(() => {
    return segmentsAfterNormalize
      .filter((segment: Segment) => segment.is_chapter)
      .map((segment: any, index: number) => {
        const { newStartTimeChapter, newEndTimeChapter } = segment;
        if (newStartTimeChapter === null || !newEndTimeChapter === null) return null;

        const duration = newEndTimeChapter - newStartTimeChapter;
        const durationInMilliseconds = duration * 1000;
        const itemWidth = convertMillisecondToPixel(durationInMilliseconds, scale);
        const positionStart = convertMillisecondToPixel(newStartTimeChapter * 1000, scale);

        return (
          <div
            className="time-line__chapter-label"
            style={{ width: itemWidth, left: positionStart }}
            key={segment.fingerprint}
            title={segment.text}
          >
            <div className="time-line__chapter-label-bg"></div>
            <span className="time-line__chapter-label-text">{segment.text}</span>
          </div>
        );
      });
  }, [segmentsAfterNormalize, scale]);

  const widthMain = useMemo(() => {
    const latestTimeRuler = anchorTimeRuler.slice(-1)[0];
    if (!latestTimeRuler || !timeLineWorkplaceRef.current || !timeLineMainRef.current) return;

    const timeInMilliseconds: number = latestTimeRuler * 1000;
    const position: number = convertMillisecondToPixel(timeInMilliseconds, scale);

    const hasScrollBar = timeLineWorkplaceRef.current?.scrollWidth !== timeLineWorkplaceRef.current?.offsetWidth;
    const newScrollBarWidth = position - timeLineWorkplaceRef.current?.offsetWidth;

    if (hasScrollBar) scrollBarWidth.current = newScrollBarWidth < 0 ? null : newScrollBarWidth;
    else scrollBarWidth.current = null;

    if (position < timeLineWorkplaceRef.current?.offsetWidth) return;

    return position;
  }, [scale, anchorTimeRuler]);

  return (
    <React.Fragment>
      <TimeLineTopBar
        getFormatDurationAllSegments={getFormatDurationAllSegments}
        globalCurrentTime={globalCurrentTime}
        expandLayoutView={expandLayoutView}
        isExpandLayoutView={isExpandLayoutView}
        toggleToolbar={toggleToolbar}
        handleInsertTransition={handleInsertTransition}
      />
      <div className="time-line__wrapper">
        <React.Fragment>
          <div className="time-line__wrapper__section-1" ref={sectionOneRef}>
            <div className="time-line__wrapper__section-1__items ruler">
              <div className="item">
                <button className="btn-add-caption">
                  <i className="fa fa-plus-circle"></i>
                  Add caption
                </button>
              </div>
            </div>
            <React.Fragment>{renderListTitleCaptionTrack}</React.Fragment>
            <div className="time-line__wrapper__section-1__items video-track">
              <div className="item">
                <div className="video-track-title">Video Track</div>
                {/* <div onClick={handleChangeVisibilityVideoTrack}>
                  {visibilityVideoTrack ? <IconVisibility /> : <IconInvisibility />}
                </div> */}
              </div>
            </div>
            <div className="time-line__wrapper__section-1__items seekbar">
              <div className="hidden-item"></div>
            </div>
          </div>
        </React.Fragment>

        <React.Fragment>
          <div className="time-line__wrapper__section-2" ref={sectionTwoRef}>
            <div className="time-line__workplace" ref={timeLineWorkplaceRef}>
              <div
                style={{ display: currentlyPlayingVideo === null ? 'none' : 'block' }}
                className="time-line__seekbar"
                ref={seekbarRef}
              >
                <span className="time-line__seekbar-label" ref={currentTimeHoverRef}></span>
              </div>

              <div ref={timeLineMainRef} style={{ width: widthMain ?? '100%' }}>
                <TimeLineRuler
                  anchorTimeRuler={anchorTimeRuler}
                  scale={scale}
                  handleClickRulerItem={handleClickRulerItem}
                />
                <React.Fragment>{renderChapterLabel}</React.Fragment>
                <React.Fragment>{renderListCaptionTrack}</React.Fragment>
                <div
                  // ref={cardHolder}
                  className={`time-line__track time-line__track__video-track ${
                    !visibilityVideoTrack ? 'hidden-track' : ''
                  }`}
                >
                  <DndProvider backend={HTML5Backend}>
                    <React.Fragment>{renderVideoTrack}</React.Fragment>
                  </DndProvider>
                </div>
              </div>
            </div>
            <div>
              <div style={{ paddingTop: 20 }}></div>
              <TimeLineScrollBar
                scrollToPercentage={scrollToPercentage}
                scaleTo={scaleTo}
                timeLineWorkplaceRef={timeLineWorkplaceRef}
                isPaused={isPaused}
                scale={scale}
                globalCurrentTime={globalCurrentTime}
              />
            </div>
          </div>
        </React.Fragment>
      </div>
    </React.Fragment>
  );
}

function TimeLineTopBar({
  getFormatDurationAllSegments,
  globalCurrentTime,
  expandLayoutView,
  isExpandLayoutView,
  toggleToolbar,
}: any) {
  const currentTimeRef = useRef<null | HTMLDivElement>(null);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    updateCurrentTime(globalCurrentTime);
  }, [globalCurrentTime]);

  function formatTime(time: number) {
    const result = moment.duration(String(time), 'seconds') as Duration;
    return result.format('hh:mm:ss', 0, { trim: false });
  }

  function updateCurrentTime(time: number | null) {
    if (!currentTimeRef.current || time === null) return;
    currentTimeRef.current.innerText = formatTime(time);
  }

  return (
    <div className="time-line__topbar">
      <div className={`time-line__tools ${isAuthenticated ? '' : 'time-line__tools__inactive'}`}>
        {/* <div className="time-line__tool">
          <button style={{ marginTop: 4 }}>
            <TimeLineTool1 />
          </button>
        </div>
        <div className="time-line__tool">
          <button>
            <TimeLineTool2 />
          </button>
        </div>
        <div className="time-line__tool">
          <button onClick={expandLayoutView}>
            <TimeLineTool3 isExpandLayoutView={isExpandLayoutView} />
          </button>
        </div>
        <div className="time-line__tool">
          <button>
            <TimeLineTool4 />
          </button>
        </div>
        <div className="time-line__tool">
          <button>
            <TimeLineTool5 />
          </button>
        </div>
        <div className="time-line__tool">
          <button>
            <TimeLineTool6 />
          </button>
        </div> */}
        <div className="time-line__tool">
          <button onClick={() => toggleToolbar('transition')}>
            <TimeLineTool7 />
          </button>
        </div>
      </div>
      <div className="time-line__time-display">
        <div className="time-line__time-current" ref={currentTimeRef}>
          00:00:00
        </div>
        <div className="time-line__time-separator"> / </div>
        <div className="time-line__time-duration">{getFormatDurationAllSegments}</div>
      </div>
      <div style={{ flex: 1 }}></div>
    </div>
  );
}

function TimeLineRuler({
  anchorTimeRuler,
  scale,
  handleClickRulerItem,
}: {
  anchorTimeRuler: number[];
  scale: number;
  handleClickRulerItem: (time: number) => void;
}) {
  const timeLineRulerRef = useRef<null | HTMLDivElement>(null);

  function handleClickRuler(event: any) {
    if (timeLineRulerRef.current === null) return;

    const rect = timeLineRulerRef.current.getBoundingClientRect();
    let position: number = event.clientX - rect.x;
    position = position < 0 ? rect.x : position;
    const milliseconds = convertPixelToMilliseconds(position, scale, true);
    handleClickRulerItem(milliseconds / 1000);
  }

  const renderRuler = useMemo(() => {
    return anchorTimeRuler.map((anchorTime, idx) => {
      const timeInMilliseconds: number = anchorTime * 1000;
      const position: number = convertMillisecondToPixel(timeInMilliseconds, scale);
      const isLatestItem = anchorTimeRuler.length - 1 === idx;

      if (isLatestItem) return null;

      const nextTimeInMilliseconds: number = anchorTimeRuler[idx + 1] * 1000;
      const nextPostion: number = convertMillisecondToPixel(nextTimeInMilliseconds, scale);
      const step = (nextPostion - position) / NUMBER_OF_SHORT_RULER_BETWEEN;
      const positionShortRulers: number[] = generateFrom(position, nextPostion, step);
      positionShortRulers.shift();

      return (
        <React.Fragment key={idx}>
          <div className="time-line__ruler-item" style={{ left: position }}>
            <span className="time-line__ruler-time">{getFormatDuration(timeInMilliseconds)}</span>
          </div>
          {positionShortRulers.map((left, idx) => (
            <div
              key={'short-ruler-' + idx}
              className="time-line__ruler-item short"
              style={{ left }}
              // onClick={handleClickRuler}
            ></div>
          ))}
        </React.Fragment>
      );
    });
  }, [anchorTimeRuler, scale]);

  return (
    <div ref={timeLineRulerRef} className="time-line__ruler" onClick={handleClickRuler}>
      {renderRuler}
    </div>
  );
}

function AudioTrack({ segment, scale }: any) {
  const { startNew, endNew, text } = segment;
  const duration = endNew - startNew;
  const durationInMilliseconds = duration * 1000;
  const itemWidth = convertMillisecondToPixel(durationInMilliseconds, scale);
  const positionStart = convertMillisecondToPixel(startNew * 1000, scale);

  return (
    <div className="time-line__audio" style={{ width: itemWidth, left: positionStart }}>
      {/* {text} */}
    </div>
  );
}

function VideoTrack({
  segment,
  index,
  scale,
  moveSegment,
  handlePlayAtIndex,
  currentlyPlayingVideo,
  isLastItem,
  toolbar,
  handleInsertTransition,
  forwardPlayerRef,
}: any) {
  const { startNew, endNew, chapterCount, is_chapter, chapterIdx, fingerprint } = segment;
  const duration = endNew - startNew;
  const durationInMilliseconds = duration * 1000;
  const itemWidth = convertMillisecondToPixel(durationInMilliseconds, scale);
  const positionStart = convertMillisecondToPixel(startNew * 1000, scale);
  const anchorLeftRef = useRef<HTMLDivElement>(null);
  const anchorRightRef = useRef<HTMLDivElement>(null);
  const [isOpenThread, setIsOpenThread] = useState(false);

  const ref = useRef<HTMLDivElement>(null);
  const [isLeftToRight, setIsLeftToRight] = useState(false);
  const [isRightToLeft, setIsRightToLeft] = useState(false);

  const interpolateSegmentIdx = index - chapterCount;

  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CHAPTER_ITEM,
    collect(monitor: DropTargetMonitor) {
      return {
        isOver: monitor.isOver() && monitor.getItem<any>().index !== index,
      };
    },
    drop(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) return;

      const prevIndex: number = item.index;
      const nextIndex: number = index;
      const prevChapterIdx = item.chapterIdx;
      const nextChapterIdx = index;
      const isRTL = prevIndex > nextIndex;

      if (prevIndex === nextIndex) return;

      moveSegment(prevChapterIdx, nextChapterIdx, prevIndex, nextIndex + (isRTL ? 1 : 0), true);
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();

      if (hoverBoundingRect !== undefined) {
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
        const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          setIsLeftToRight(true);
          setIsRightToLeft(false);
          return;
        }

        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          setIsRightToLeft(true);
          setIsLeftToRight(false);
          return;
        }
      }
    },
  });

  const [{ isDragging }, drag] = useDrag(
    {
      type: ItemTypes.CHAPTER_ITEM,
      item: () => {
        return { index, chapterIdx };
      },
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [index, chapterIdx],
  );

  useLayoutEffect(() => {
    drag(drop(ref));

    return () => {
      drag(null);
      drop(null);
    };
  }, [drag, drop, ref]);

  useEffect(() => {
    setIsOpenThread(currentlyPlayingVideo === interpolateSegmentIdx);
  }, [currentlyPlayingVideo, interpolateSegmentIdx]);

  function insertTransition() {
    const idx = interpolateSegmentIdx + 1;
    handleInsertTransition(idx, segment);
  }

  const renderMedia = useMemo(() => {
    const type = segment?.imported_media?.type;

    if (type === 'video') {
      return (
        <React.Fragment>
          <div className="segment-thumb">
            <div
              className="time-line__media-thumbnail"
              style={{ backgroundImage: 'url("https://app.simonsays.ai/img/poster-audio.png")' }}
            ></div>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div className="segment-thumb">
          <div
            className="time-line__media-thumbnail"
            style={{ backgroundImage: 'url("https://app.simonsays.ai/img/poster-audio.png")' }}
          ></div>
        </div>
      </React.Fragment>
    );
  }, [segment.imported_media]);

  if (is_chapter) {
    return <div style={{ display: 'none' }}></div>;
  }

  const opacity = isDragging ? 0 : 1;

  return (
    <div
      key={fingerprint}
      ref={ref}
      className={`time-line__video segment-holder 
      ${currentlyPlayingVideo === interpolateSegmentIdx ? 'time-line__video__playing-border' : ''}
      ${isLeftToRight && isOver ? 'time-line__video__border-ltr' : ''}
      ${isRightToLeft && isOver ? 'time-line__video__border-rtl' : ''}
      `}
      style={{ width: itemWidth, left: positionStart, opacity }}
    >
      {segment.annotations?.length > 0 && itemWidth > 50 ? (
        <img
          className="icon-note-img"
          src="/img/icon-note.png"
          onClick={() => handlePlayAtIndex(interpolateSegmentIdx)}
        />
      ) : null}

      <div className="time-line__video__anchor time-line__video__anchor__left" ref={anchorLeftRef}></div>
      <React.Fragment>{renderMedia}</React.Fragment>
      <div className="time-line__video__anchor time-line__video__anchor__right" ref={anchorRightRef}>
        {toolbar.isOpenTransition && (
          <span onClick={insertTransition} className={`tool-icon ${isLastItem ? 'last' : ''}`}>
            <TimeLineTool7 height={10} width={10} />
          </span>
        )}
      </div>

      <React.Fragment>
        <Thread
          hasAnnotations={!!segment.annotations?.length}
          isOpen={isOpenThread}
          toggle={() => setIsOpenThread(prevState => !prevState)}
          forwardKey={segment.fingerprint}
          target={forwardPlayerRef}
        >
          {isOpenThread && (
            <ThreadPopover
              annotations={segment.annotations || []}
              segmentFingerprint={segment.fingerprint}
              toggle={() => setIsOpenThread(prevState => !prevState)}
            />
          )}
        </Thread>
      </React.Fragment>
    </div>
  );
}

function TimeLineScrollBar({
  scrollToPercentage,
  scaleTo,
  timeLineWorkplaceRef,
  globalCurrentTime,
  isPaused,
  scale,
}: {
  scrollToPercentage: (percentageWidth: number) => void;
  scaleTo: (numScale: number) => void;
  timeLineWorkplaceRef: any;
  isPaused: boolean;
  globalCurrentTime: any;
  scale: any;
}) {
  const scrollBarWrapperRef = useRef<null | HTMLDivElement>(null);
  const scrollBarRef = useRef<null | HTMLDivElement>(null);
  const scrollBarLeftCircleRef = useRef<null | HTMLDivElement>(null);
  const scrollBarLeftRightRef = useRef<null | HTMLDivElement>(null);
  const scrollBarStickRef = useRef<null | HTMLDivElement>(null);
  const isExpandStick = useRef(false);

  const vwCurrent = useRef(0);
  const percent = useRef(0);

  const [itemScrolling, setItemScrolling] = useState<any>({});

  useEffect(() => {
    vwCurrent.current = timeLineWorkplaceRef.current?.clientWidth - OFFSET_MOVE_SCROLLBAR_AUTO;
    percent.current = 0;
  }, [timeLineWorkplaceRef.current?.clientWidth, itemScrolling.percentageWidth]);

  useEffect(() => {
    if (globalCurrentTime === null || isPaused) return;

    const position: number = convertMillisecondToPixel(globalCurrentTime * 1000, scale);
    if (position > vwCurrent.current) {
      if (!scrollBarRef.current) return;
      const scrollBarEl = scrollBarRef.current;
      if (parseInt(scrollBarEl.style.left) === 0) {
        percent.current = 1;
      } else {
        percent.current += 1;
      }
      const newLeft = percent.current * 10;
      scrollToPercentage(percent.current);
      scrollBarEl.style.left = `${newLeft}px`;
      vwCurrent.current = vwCurrent.current + OFFSET_MOVE_SCROLLBAR_AUTO;
    }
  }, [globalCurrentTime, isPaused]);

  useEffect(() => {
    let isDown = false;
    let offset = [0, 0];

    if (!scrollBarWrapperRef.current || !scrollBarRef.current || !scrollBarStickRef.current) return;

    function onMousedown(event: MouseEvent) {
      if (!scrollBarWrapperRef.current || !scrollBarRef.current || !scrollBarStickRef.current) return;
      const scrollBarEl = scrollBarRef.current;

      isDown = true;
      offset = [scrollBarEl.offsetLeft - event.clientX, scrollBarEl.offsetTop - event.clientY];
    }

    function onMouseUp(event: MouseEvent) {
      isDown = false;
    }

    function onMouseMove(event: MouseEvent) {
      event.preventDefault();
      if (isExpandStick.current) return;
      if (!scrollBarWrapperRef.current || !scrollBarRef.current || !scrollBarStickRef.current) return;
      const scrollBarEl = scrollBarRef.current;

      if (isDown) {
        const mousePosition = {
          x: event.clientX,
          y: event.clientY,
        };

        const widthStick = scrollBarStickRef.current.offsetWidth;
        const widthWrapper = scrollBarWrapperRef.current.offsetWidth - widthStick;
        const newLeft = mousePosition.x + offset[0];
        const newRight = newLeft + widthStick;
        const limitLeft = 0;
        const limitRight = scrollBarWrapperRef.current.offsetWidth;
        const isReachLimitLeft = newLeft < limitLeft;
        const isReachLimitRight = newRight > limitRight;

        if (isReachLimitLeft || isReachLimitRight) return;

        const percentageWidth = Math.round((newLeft / widthWrapper) * 100);
        // console.log(percentageWidth, newLeft)
        scrollToPercentage(percentageWidth);
        setItemScrolling({ isDown: isDown, percentageWidth: percentageWidth });
        scrollBarEl.style.left = `${newLeft}px`;
      }
    }

    scrollBarRef.current.addEventListener('mousedown', onMousedown, false);
    document.addEventListener('mouseup', onMouseUp, false);
    scrollBarWrapperRef.current.addEventListener('mousemove', onMouseMove, false);

    return () => {
      if (!scrollBarWrapperRef.current || !scrollBarRef.current) return;

      scrollBarRef.current.removeEventListener('mousedown', onMousedown, false);
      document.removeEventListener('mouseup', onMouseUp, false);
      scrollBarWrapperRef.current.removeEventListener('mousemove', onMouseMove, false);
    };
  }, []);

  useEffect(() => {
    let isDown = false;
    let offset = [0, 0];
    let widthStick: null | number = null;

    if (!scrollBarWrapperRef.current || !scrollBarLeftRightRef.current) return;

    function onMousedown(event: MouseEvent) {
      event.stopPropagation();
      isExpandStick.current = true;

      if (!scrollBarWrapperRef.current || !scrollBarRef.current || !scrollBarStickRef.current) return;
      const scrollBarEl = scrollBarRef.current;

      isDown = true;
      offset = [scrollBarEl.offsetLeft - event.clientX, scrollBarEl.offsetTop - event.clientY];
      widthStick = scrollBarStickRef.current.offsetWidth;
    }

    function onMouseUp(event: MouseEvent) {
      isExpandStick.current = false;
      isDown = false;
    }

    function onMouseMove(event: MouseEvent) {
      event.preventDefault();
      if (!scrollBarWrapperRef.current || !scrollBarRef.current || !scrollBarStickRef.current || !widthStick) return;

      if (isDown) {
        const mousePosition = {
          x: event.clientX,
          y: event.clientY,
        };

        const widthWrapper = scrollBarWrapperRef.current.offsetWidth;
        const offsetRight = mousePosition.x + offset[0];
        const newStickWidth = widthStick + offsetRight;
        const minStickWidth = PIXEL_PER_UNIT_SCALE;
        const maxStickWidth = widthWrapper;
        if (newStickWidth < minStickWidth || newStickWidth > maxStickWidth) return;

        scaleTo(newStickWidth / PIXEL_PER_UNIT_SCALE);
        scrollBarStickRef.current.style.width = `${newStickWidth}px`;
      }
    }

    scrollBarLeftRightRef.current.addEventListener('mousedown', onMousedown, false);
    document.addEventListener('mouseup', onMouseUp, false);
    scrollBarWrapperRef.current.addEventListener('mousemove', onMouseMove, false);

    return () => {
      if (!scrollBarLeftRightRef.current || !scrollBarWrapperRef.current) return;

      scrollBarLeftRightRef.current.addEventListener('mousedown', onMousedown, false);
      document.addEventListener('mouseup', onMouseUp, false);
      scrollBarWrapperRef.current.addEventListener('mousemove', onMouseMove, false);
    };
  }, []);

  return (
    <div ref={scrollBarWrapperRef} style={{ height: 40 }}>
      <div className="time-line__scrollbar-wrapper">
        <div ref={scrollBarRef} className="time-line__scrollbar">
          <div ref={scrollBarLeftCircleRef} className="time-line__scrollbar-left-circle"></div>
          <div
            style={{ width: PIXEL_PER_UNIT_SCALE }}
            ref={scrollBarStickRef}
            className="time-line__scrollbar-stick"
          ></div>
          <div ref={scrollBarLeftRightRef} className="time-line__scrollbar-right-circle"></div>
        </div>
      </div>
    </div>
  );
}

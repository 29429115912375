import { css } from "emotion";

const buttonStyles = {
  buttonWrapper: css({
    display: "inline-block",
  }),

  button: css({
    background: "#13162c",
    color: "#8f9bb3",
    fontSize: "18px",
    border: 0,
    paddingTop: "0px",
    verticalAlign: "bottom",
    height: "34px",
    width: "36px",
    "&:hover,&:focus": {
      background: "#2b2e42",
      outline: 0,
    },
    "& svg": {
      fill: "#8f9bb3",
      "& text": {
        fill: "#8f9bb3",
      },
    },
  }),

  active: css({
    background: "#13162c",
    fontWeight: 800,
    color: "#8f9bb3",
    "& svg": {
      fill: "#8f9bb3",
      fontWeight: 800,
      "& text": {
        fontWeight: 800,
        fill: "#8f9bb3",
      },
    },
  }),
};

const toolbarStyles = {
  toolbar: css({
    left: "50%",
    transform: "translate(-50%) scale(0)",
    position: "absolute",
    border: "0",
    background: "#13162c",
    borderRadius: "2px",
    boxShadow: "0px 1px 3px 0px rgba(0, 0, 0, 1)",
    zIndex: 2,
    boxSizing: "border-box",
    "&:after,&:before": {
      top: "100%",
      left: "50%",
      border: "solid transparent",
      content: '" "',
      height: 0,
      width: 0,
      position: "absolute",
      pointerEvents: "none",
    },
    "&:after": {
      borderColor: " rgba(255, 255, 255, 0)",
      borderTopColor: "#fff",
      borderWidth: "4px",
      marginLeft: "-4px",
    },
    "&:before": {
      borderColor: " rgba(221, 221, 221, 0)",
      borderTopColor: "#ddd",
      borderWidth: "6px",
      marginLeft: "-6px",
    },
  }),
};

export { buttonStyles, toolbarStyles };

import React from 'react';
import { Popover } from 'reactstrap';
import * as Popper from 'popper.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-regular-svg-icons';
import { faStickyNote as faStickyNoteSolid } from '@fortawesome/free-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { GET_IS_EDIT_MODE } from '../../reactivities/operations/queries/editMode/getEditMode.query';

const modifiers = {
  //option in popperjs 1.x
  offset: {
    offset: '0, 25',
  },
  preventOverflow: {
    boundariesElement: 'viewport',
  },
};

type ThreadProps = {
  forwardKey?: any;
  isOpen: boolean;
  hasAnnotations: boolean;
  children: any;
  toggle: () => void;
  target?: string | HTMLElement | React.RefObject<HTMLElement>;
};

export default function Thread(props: ThreadProps) {
  const isEditMode = useQuery(GET_IS_EDIT_MODE);
  const { forwardKey, isOpen, hasAnnotations, toggle, children, target } = props;

  if (target) {
    return (
      <React.Fragment>
        <span onMouseDown={e => e.stopPropagation()} className="inner-toolbar inner-toolbar-right annotation-row">
          <Popover
            className={`thread ${isEditMode.data.isEditMode ? 'edit-mode' : ''}`}
            fade={false}
            trigger="click"
            placement="right-start"
            isOpen={isOpen}
            target={target}
          >
            {({ scheduleUpdate }) => {
              scheduleUpdate();
              return children;
            }}
          </Popover>
        </span>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <span onMouseDown={e => e.stopPropagation()} className="inner-toolbar inner-toolbar-right annotation-row">
        <span id={`popover-thread-${forwardKey}`} title="Thread">
          <FontAwesomeIcon icon={hasAnnotations ? faStickyNoteSolid : faStickyNote} width={16} height={16} />
        </span>
        <Popover
          className={`thread ${isEditMode.data.isEditMode ? 'edit-mode' : ''}`}
          fade={false}
          modifiers={modifiers as Popper.Modifiers}
          trigger="click"
          // placement="right-start"
          isOpen={isOpen}
          target={`popover-thread-${forwardKey}`}
          toggle={toggle}
        >
          {({ scheduleUpdate }) => {
            scheduleUpdate();
            return children;
          }}
        </Popover>
      </span>
    </React.Fragment>
  );
}

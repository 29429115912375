import React from 'react';

const IconInvisibility = () => {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.94141 2.75H5C5.33854 2.75 5.63151 2.8737 5.87891 3.12109C6.1263 3.36849 6.25 3.66146 6.25 4V4.07812L4.94141 2.75ZM3.14453 3.08203C2.98828 3.39453 2.91016 3.70052 2.91016 4C2.91016 4.57292 3.11198 5.06771 3.51562 5.48438C3.93229 5.88802 4.42708 6.08984 5 6.08984C5.29948 6.08984 5.60547 6.01172 5.91797 5.85547L5.27344 5.21094C5.16927 5.23698 5.07812 5.25 5 5.25C4.66146 5.25 4.36849 5.1263 4.12109 4.87891C3.8737 4.63151 3.75 4.33854 3.75 4C3.75 3.92188 3.76302 3.83073 3.78906 3.72656L3.14453 3.08203ZM0.839844 0.777344L1.36719 0.25L8.75 7.63281L8.22266 8.16016C8.15755 8.09505 7.94922 7.89323 7.59766 7.55469C7.25911 7.21615 6.9987 6.95573 6.81641 6.77344C6.25651 7.00781 5.65104 7.125 5 7.125C3.97135 7.125 3.04036 6.83854 2.20703 6.26562C1.3737 5.69271 0.77474 4.9375 0.410156 4C0.735677 3.19271 1.25651 2.5026 1.97266 1.92969C1.81641 1.77344 1.59505 1.55208 1.30859 1.26562C1.03516 0.979167 0.878906 0.816406 0.839844 0.777344ZM5 1.91016C4.73958 1.91016 4.48568 1.96224 4.23828 2.06641L3.33984 1.16797C3.84766 0.972656 4.40104 0.875 5 0.875C6.02865 0.875 6.95312 1.16146 7.77344 1.73438C8.60677 2.30729 9.20573 3.0625 9.57031 4C9.25781 4.76823 8.78255 5.42578 8.14453 5.97266L6.93359 4.76172C7.03776 4.51432 7.08984 4.26042 7.08984 4C7.08984 3.42708 6.88151 2.9388 6.46484 2.53516C6.0612 2.11849 5.57292 1.91016 5 1.91016Z"
        fill="#8F9BB3"
      />
    </svg>
  );
};

export default IconInvisibility;
